import React, { useContext, useRef, useState } from 'react';
import { Button, Skeleton, Tabs, Typography } from 'antd';
import GeneralTab from '../features/settings/components/GeneralTab';
import BusinessContext from '../contexts/BusinessContext';
import ContactsTab from '../features/settings/components/ContactsTab';
import BusinessHoursTab from '../features/settings/components/BusinessHoursTab';
import BookingPageTab from '../features/settings/components/BookingPageTab';
import useBusinessData from '../hooks/useBusinessData';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const SettingsPage = () => {
    const [activeTabKey, setActiveTabKey] = useState(1);
    const [saveLoading, setSaveLoading] = useState(false);

    const { business } = useContext(BusinessContext);
    const { businessData, loading } = useBusinessData(business.id);
    const isMobile = useCheckMobileScreen();

    const generalSettingsRef = useRef();
    const contactsRef = useRef();
    const businessHoursRef = useRef();
    const bookingPageRef = useRef();

    const saveHandler = async () => {
        const tab = tabs.find(x => x.key === activeTabKey);
        tab.reference.current.submit();
    };

    const colSize = isMobile ? 24 : 12;
    const tabsProps = { businessId: business.id, data: businessData, setSaveLoading, colSize };

    const tabs = [
        {
            key: 1,
            label: 'General',
            children: <GeneralTab {...tabsProps} ref={generalSettingsRef} />,
            reference: generalSettingsRef
        },
        {
            key: 2,
            label: 'Contacts',
            children: <ContactsTab {...tabsProps} ref={contactsRef} />,
            reference: contactsRef
        },
        {
            key: 3,
            label: 'Business hours',
            children: <BusinessHoursTab {...tabsProps} ref={businessHoursRef} />,
            reference: businessHoursRef
        },
        {
            key: 4,
            label: 'Booking page',
            children: <BookingPageTab {...tabsProps} ref={bookingPageRef} />,
            reference: bookingPageRef
        }
    ];

    return (
        <div className={`page ${isMobile ? 'mobile' : ''}`}>
            <div className='page__header'>
                <Typography.Title level={3} className='page-title'>Settings</Typography.Title>
            </div>
            <Typography.Text type='secondary'>Manage your business settings</Typography.Text>
            {loading 
                ? <Skeleton active />
                : <Tabs className='business-settings-tabs' items={tabs} onChange={tab => setActiveTabKey(tab)} />}
            <div className='business-settings-save-wrapper'>
                <Button type='primary' onClick={saveHandler} loading={saveLoading}>Save</Button>
            </div>
        </div>
    );
};

export default SettingsPage;