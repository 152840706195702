import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Calendar, Typography } from 'antd';
import axios from 'axios';

import BusinessContext from '../../contexts/BusinessContext';

import BookingDialog from '../../features/booking/BookingModal';
import useEmployee from '../../features/employees/hooks/useEmployee';
import useService from '../../features/services/hooks/useService';
import CalendarHeader from '../../features/booking/CalendarHeader';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const SlotsPage = () => {
    const { employeeId, serviceId } = useParams();
    const [slots, setSlots] = useState();
    const [date, setDate] = useState();
    const [bookingModal, setBookingModal] = useState({ open: false, booking: {} });

    const business = useContext(BusinessContext);
    const { employee } = useEmployee(employeeId, business?.id);
    const { service } = useService(serviceId, business?.id);
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        if (business?.id && employeeId && serviceId && date) {
            const formattedDate = date.startOf('day').format('YYYY-MM-DD');

            const url = `${process.env.REACT_APP_API}/business/${business.id}/appointments/slots?` +
                `employeeId=${employeeId}&serviceId=${serviceId}&date=${formattedDate}`

            axios.get(url).then(response => setSlots(response.data));
        }
    }, [date, business, employeeId, serviceId]);

      const disabledDate = (current) => {
        return current && current < new Date().setHours(0, 0, 0, 0);
      };

    const mobile = isMobile ? 'mobile' : '';

    return service && employee && (
        <div>
            <Typography className='booking-page-listing-title'>
                {`Choose your preferred date to see all available time slots with ${employee.name} for a ${service.name}`}
            </Typography>
            <div className={`booking-page-calendar-slots-wrapper ${mobile}`}>
                <div className={`booking-page-calendar-slots-wrapper__calendar ${mobile}`}>
                    <Calendar
                        headerRender={CalendarHeader}
                        disabledDate={disabledDate}
                        fullscreen={false}
                        onChange={x => setDate(x)}
                    />
                </div>
                {date && slots?.length !== 0 && (
                    <div className={`booking-page-slots-wrapper ${mobile}`}>
                        {slots?.map(slot => (
                            <Button
                                onClick={() => setBookingModal({ open: true, booking: {
                                    slot: slot.slot,
                                    date: date,
                                    business: business,
                                    employee: employee,
                                    service: service
                                } })}
                                disabled={!slot.available}
                                type='default'
                            >
                                {slot.slot}
                            </Button>)
                        )}
                    </div>
                )}
                {date && !slots?.length && (
                    <Typography className={`booking-page-calendar-slots-wrapper__no-slots ${mobile}`}>
                        No available slots for the selected date
                    </Typography>
                )}
            </div>
            {bookingModal.open && (
                <BookingDialog
                    open
                    booking={bookingModal.booking}
                    closeHandler={() => setBookingModal({ open: false, booking: {} })}
                />)}
        </div>
    )
};

export default SlotsPage;