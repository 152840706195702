import { useNavigate } from 'react-router-dom';
import { Avatar, Card, Space, Typography } from 'antd';

const TeamPage = ({employees, serviceId, currency, businessSlug}) => {
    const navigate = useNavigate();

    const handleClick = employeeId => {
        if (serviceId)
            navigate(`/${businessSlug}/slots/${employeeId}/${serviceId}`);
        else
            navigate(`team/${employeeId}`);
    }

    return employees?.map(employee => (
        <Card key={employee.id} className='business-page-card' onClick={() => handleClick(employee.id)}>
            <Space size='large'>
                <Avatar size='large' src={employee.image}>{employee.name.slice(0, 2).toUpperCase()}</Avatar>
                {serviceId ? (
                    <div>
                       <Typography>{employee.name}</Typography>
                       <Typography>{`${employee.price} ${currency}`}</Typography>
                    </div>
                ) : <Typography>{employee.name}</Typography>}
            </Space>
        </Card>
    ))
};

export default TeamPage;