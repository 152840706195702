import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

const useUserBusinesses = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetch = useCallback(async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/Users/businesses`);
            setData(response.data);
        }
        catch(error) {}

        setLoading(false);
    }, []);

    useEffect(() => { fetch() }, [fetch]);

    return { data, loading, refetch: fetch }
}

export default useUserBusinesses;