import axios from "axios";

const saveBreaks = async (employeeId, businessId, form) => {
    try {
        const fieldsValues = await form.validateFields();
        const daysOfWeekWithBreaks = Object.values(fieldsValues).filter(x => x.intervals?.length);

        await axios.put(
            `${process.env.REACT_APP_API}/business/${businessId}/employees/${employeeId}/update-breaks`,
            {breaks: daysOfWeekWithBreaks});

        return true;
    }
    catch(error) {
        console.log(error);
        return false;
    }
}

export default saveBreaks;