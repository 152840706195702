import { Divider, Space, Typography } from "antd"
import { BsInstagram, BsFacebook, BsGlobe2, BsGeoAlt, BsEnvelope, BsTelephone, BsYoutube } from 'react-icons/bs';
import ContactsEntry from "./ContactsEntry"
import SocialMediaButton from "./SocialMediaButton"

const BusinessInfo = ({businessData, businessHours}) => (
    <div>
        <div className='business-page-hours'>
            {Object.entries(businessHours).map(([key, value]) => (
                <Space key={key} className='business-page-hours__entry'>
                    <Typography>{key}</Typography>
                    <Typography>{value}</Typography>
                </Space>
            ))}
            <Typography className='business-page-hours__timezone'>
                Timezone ({businessData?.timezone})
            </Typography>
        </div>
        <Divider />
        <div className='business-page-contacts'>
            <ContactsEntry icon={<BsGeoAlt />} value={businessData?.address} />
            <ContactsEntry icon={<BsEnvelope />} value={businessData?.contacts?.email} />
            <ContactsEntry icon={<BsTelephone />} value={businessData?.contacts?.primaryPhone} />
            <ContactsEntry icon={<BsTelephone />} value={businessData?.contacts?.secondaryPhone} />
        </div>
        <Space className='business-page-social-media-icons'>
            <SocialMediaButton
                icon={<BsInstagram />}
                link={businessData?.contacts?.instagram}
            />
            <SocialMediaButton
                icon={<BsFacebook />}
                link={businessData?.contacts?.facebook}
            />
            <SocialMediaButton
                icon={<BsGlobe2 />}
                link={businessData?.contacts?.website}
            />
            <SocialMediaButton
                icon={<BsYoutube />}
                link={businessData?.contacts?.youtube}
            />
        </Space>
    </div>
)

export default BusinessInfo;