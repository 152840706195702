import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Form, Row, Segmented, Select, Space, Typography, DatePicker } from 'antd';
import { DeleteOutlined, PauseCircleOutlined } from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

import PastTimeOff from './PastTimeOff';
import { requiredNoMessage } from '../../../helpers/form-validators';

const EditTimeOff = ({form, timeOffs, timezone, businessId}) => {
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [segmentedValue, setSegmentedValue] = useState('Upcoming');
    const MomentDatePicker = DatePicker.generatePicker(momentGenerateConfig);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/business/${businessId}/employees/leave-types`)
            .then(response => {
                const options = response.data.map(x => ({
                    value: x.id,
                    label: x.name,
                    requiresTime: x.requiresTime
                }))

                setLeaveTypes(options);
            });
    }, [businessId]);

    const initialValues = {timeOffs: timeOffs.upcomings.map(timeOff => ({
        ...timeOff,
        start: moment.utc(timeOff.start).tz(timezone),
        end: moment.utc(timeOff.end).tz(timezone)
    }))};

    return (
        <React.Fragment>
            <Typography.Text type='secondary' style={{marginBottom: '20px', display: 'block'}}>
                Configure employee time offs for vacations, paid, unpaid, sick or shift leaves.
            </Typography.Text>
            <Segmented options={['Upcoming', 'Past']} onChange={setSegmentedValue} block style={{marginBottom: '20px'}}/>
            <Divider />
            {segmentedValue === 'Upcoming' ? (
                <Form layout='vertical' form={form} initialValues={initialValues}>
                    <Form.List name='timeOffs'>
                        {(fields, { add, remove }) => (
                            <React.Fragment>
                                {fields.length ? fields.map(({ key, name }) => (
                                    <Row key={key} gutter={10} style={{marginBottom: '10px'}}>
                                        <Col span={6}>
                                            <Form.Item style={{ margin: 0 }} name={[name, 'type']}>
                                                <Select options={leaveTypes} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={18}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prev, current) =>
                                                    prev.timeOffs?.[name]?.type !== current.timeOffs?.[name]?.type}
                                            >
                                                {({ getFieldValue }) => {
                                                    const selectedType = getFieldValue(['timeOffs', name, 'type']);

                                                    const hasTime = leaveTypes
                                                        .find(x => x.value === selectedType)?.requiresTime;

                                                    const format = hasTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';

                                                    return (
                                                        <Space className='removeable-fields-pair-space'>
                                                            <Form.Item
                                                                style={{ margin: 0, flex: 1 }}
                                                                name={[name, 'start']}
                                                                rules={[{...requiredNoMessage}]}
                                                            >
                                                                <MomentDatePicker
                                                                    placeholder='Select from'
                                                                    showTime={Boolean(hasTime)}
                                                                    format={format}
                                                                    showNow={false}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                style={{ margin: 0, flex: 1 }}
                                                                name={[name, 'end']}
                                                                rules={[{...requiredNoMessage}]}
                                                            >
                                                                <MomentDatePicker
                                                                    placeholder='Select to'
                                                                    showTime={Boolean(hasTime)}
                                                                    format={format}
                                                                    showNow={false}
                                                                />
                                                            </Form.Item>
                                                            <DeleteOutlined onClick={() => remove(name)} />
                                                        </Space>
                                                    )
                                                }}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )) : (
                                    <div
                                        style={{
                                            background: "#fafafa",
                                            outline: "1px solid #f5f5f5",
                                            borderRadius: "5px",
                                            padding: "5px 10px",
                                            marginBottom: "10px"
                                        }}
                                    >
                                        <Space size="middle">
                                            <PauseCircleOutlined style={{ color: '#8c8c8c' }} />
                                            <Typography.Text type="secondary">No upcoming time offs</Typography.Text>
                                        </Space>
                                    </div>
                                )}
                                <Button onClick={() => add({ type: 1 })} block>
                                    Add
                                </Button>
                            </React.Fragment>
                        )}
                    </Form.List>
                </Form>
            ) : <PastTimeOff timeOffs={timeOffs.pasts} timezone={timezone} />}
            
        </React.Fragment>
    );
};

export default EditTimeOff;