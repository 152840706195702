import React from 'react';
import { Button, Divider, Form, Input, message, Space, Typography } from 'antd';
import { FcGoogle } from 'react-icons/fc';
import axios from 'axios';

import { passwordValidator, requiredNoMessage } from '../../../helpers/form-validators';

const Registration = ({switchFormHandler}) => {
    const submitHandler = async values => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/Identity/register`, { email: values.email, password: values.password });
    
            if (response.status == 200)
                message.success('Your account has been created successfully');
        }
        catch(error) {
            if (error.response?.status === 400) {
                const validationErrors = error.response.data;

                if (validationErrors.includes('DuplicateEmail'))
                    message.error('This email address is already in use');
            }
        }
    }

    return (
        <React.Fragment>
            <Typography.Title className='auth-box__title' level={3}>Create an account</Typography.Title>
            <Form name='login' layout='vertical' requiredMark={false} onFinish={submitHandler}>
                <Form.Item 
                    label='Email'
                    name='email'
                    rules={[{...requiredNoMessage}, { type: 'email', message: 'Please enter a valid email address' }]}
                    className='form-item-margin-10'
                    validateTrigger='submit'
                >
                        <Input />
                </Form.Item>
                <Form.Item
                    label='Password'
                    name='password'
                    rules={[{...requiredNoMessage}, { validator: passwordValidator }]}
                    validateTrigger='submit'
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item className='form-item-margin-10'>
                    <Button type='primary' htmlType='submit' block>Create an account</Button>
                </Form.Item>
            </Form>
            <Divider className='auth-box__divider' />
            <Button size='medium' icon={<FcGoogle size='20' />} iconPosition='start' block>
                Continue with Google
            </Button>
            <div className='auth-box-footer'>
                <Space>
                    <Typography.Text type='secondary'>Already have an account?</Typography.Text>
                    <Button type='link' className='btn-no-padding' onClick={() => switchFormHandler('login')}>Sign in</Button>
                </Space>
            </div>
        </React.Fragment>
    )
}

export default Registration;