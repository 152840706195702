import { useEffect, useState } from 'react';

const useAuth = () => {
    const [user, setUser] = useState();

    useEffect(() => {
        const userFromStorage = localStorage.getItem("user");

        if (userFromStorage)
            setUser(JSON.parse(userFromStorage));
        else
            setUser(null);
    }, []);

    useEffect(() => {
        if (user === undefined)
            return;

        if (user === null)
            localStorage.removeItem('user');
        else
            localStorage.setItem('user', JSON.stringify(user));
    }, [user]);

    return {user, setUser};
}

export default useAuth;