import React from "react";

import { Button, Col, Form, Input, InputNumber, Row, Space, Switch, Typography } from "antd";

const EditAttachedServices = ({form, services, currency}) => {
    const initialValues = services.reduce((acc, service) => {
        acc[`customPrice_${service.id}`] = service.customPrice;
        acc[`enabled_${service.id}`] = service.enabled;
        return acc;
    }, {});

    return (
        <React.Fragment>
            <Typography.Text
                type="secondary"
                style={{ marginBottom: "20px", display: "block" }}
            >
                Assign services and set custom prices if needed. If no custom price is specified, the employee will use the default service price for each.
            </Typography.Text>
            <Form
                form={form}
                initialValues={initialValues}
            >
                {services.map((service) => (
                    <Row key={service.id} style={{margin: "5px 0px"}}>
                        <Col span={16}>
                            <Space>
                                <Form.Item style={{margin: 0}} noStyle name={`enabled_${service.id}`}>
                                    <Switch />
                                </Form.Item>
                                <Typography>{service.name}</Typography>
                            </Space>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues[`enabled_${service.id}`] !== currentValues[`enabled_${service.id}`]}
                            >
                                {({getFieldValue}) => (
                                    <Form.Item name={`customPrice_${service.id}`} style={{margin: 0}}>
                                        <InputNumber
                                            disabled={!getFieldValue(`enabled_${service.id}`)}
                                            addonAfter={currency}
                                            min={0}
                                            placeholder="Custom price"
                                        />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                ))}
            </Form>
        </React.Fragment>
    );
};

export default EditAttachedServices;