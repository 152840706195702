import React from 'react';

import { Button, Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const CustomerActions = ({ onEdit, onDelete }) => (
    <Space>
        <Button shape="circle" icon={<EditOutlined />} onClick={onEdit} />
        <Button shape="circle" icon={<DeleteOutlined />} onClick={onDelete} danger />
    </Space>
);

export default CustomerActions;