import React, { useState } from 'react';
import { Button, Form, Input, message, Typography } from 'antd';
import axios from 'axios';
import { requiredNoMessage } from '../../../helpers/form-validators';

const ForgotPassword = ({switchFormHandler}) => {
    const [loading, setLoading] = useState(false);

    const submitHandler = async values => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/Identity/forgot-password`, { email: values.email });
    
            if (response.status == 200) {
                message.success('A password reset link has been sent to your email');
                switchFormHandler('login');
            }
        }
        catch(error) {}

        setLoading(false);
    }

    return (
        <React.Fragment>
            <Typography.Title className='auth-box__title' level={3}>Forgot password</Typography.Title>
            <Form name='login' layout='vertical' requiredMark={false} onFinish={submitHandler}>
                <Form.Item 
                    label='Email'
                    name='email'
                    rules={[{...requiredNoMessage}, { type: 'email', message: 'Please enter a valid email address' }]}
                    className='form-item-margin-10'
                    validateTrigger='submit'
                >
                        <Input />
                </Form.Item>
                <Form.Item className='form-item-margin-10'>
                    <Button loading={loading} type='primary' htmlType='submit' block>Reset password</Button>
                </Form.Item>
            </Form>
            <div className='auth-box-footer'>
                <Button
                    type='link'
                    className='btn-no-padding'
                    onClick={() => switchFormHandler('login')}
                >
                        Back to login
                </Button>
            </div>
        </React.Fragment>
    )
}

export default ForgotPassword;