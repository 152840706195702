import React, { useState } from 'react';
import { Col, Row} from 'antd'
;
import Registration from '../features/auth/components/Registration';
import Login from '../features/auth/components/Login';
import ForgotPassword from '../features/auth/components/ForgotPassword';

const Authenticate = () => {
    const [form, setForm] = useState('login');
    let component = null;

    if (form === 'registration')
        component = <Registration switchFormHandler={setForm} />;

    if (form === 'login')
        component = <Login switchFormHandler={setForm} />;

    if (form === 'forgotPassword')
        component = <ForgotPassword switchFormHandler={setForm} />;

    return (
        <Row justify='center' align='middle' className='auth-wrapper'>
            <Col xs={20} sm={14} md={10} lg={8} xl={6} className='auth-box'>
                {component}
            </Col>
        </Row>
    )
}

export default Authenticate;