import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const useOperatingEmployee = businessId => {
    const [employee, setEmployee] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (!businessId) return;

        const url = `${process.env.REACT_APP_API}/business/${businessId}/employees/operating`;
        
        axios.get(url, { headers: { 'disable-forbidden-message': true } })
            .then(response => setEmployee(response.data))
            .catch(error => error.response?.status === 403 && navigate("/account"));
    }, [businessId]);

    return employee;
}

export default useOperatingEmployee;