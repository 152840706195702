import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'antd';
import TeamPage from './TeamPage';
import BusinessContext from '../../contexts/BusinessContext';
import useServiceEmployeeListing from '../../features/services/hooks/useServiceEmployeeListing';
import useService from '../../features/services/hooks/useService';

const ServiceBookingPage = () => {
    const { businessSlug, serviceId } = useParams();

    const business = useContext(BusinessContext);
    const listing = useServiceEmployeeListing(business?.id, serviceId);
    const { service } = useService(serviceId, business?.id);

    return service && (
        <React.Fragment>
            <Typography className='booking-page-listing-title'>Select a qualified professional for {service.name}</Typography>
            {listing.employees?.length
                ? (
                    <TeamPage
                        businessSlug={businessSlug}
                        serviceId={serviceId}
                        currency={business.currency}
                        employees={listing.employees}
                    />
                ) : <Typography>No listings available</Typography>}
        </React.Fragment>
    )
}

export default ServiceBookingPage;