import { Typography } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';

import axios from "axios";

const employeeDeleteModalConfig = (employee, businessId, refetch) => ({
    title: `Confirm Deletion`,
    content: (
        <Typography.Text>
            Are you sure you want to delete the employee <b>{employee.name}</b> ({employee.email})?
        </Typography.Text>
    ),
    icon: <ExclamationCircleFilled />,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
        return axios.delete(`${process.env.REACT_APP_API}/business/${businessId}/employees/${employee.id}`).then(refetch);
    }
})

export default employeeDeleteModalConfig;