import { message } from "antd";
import axios from "axios";

const saveContacts = async (customerId, businessId, contactsForm) => {
    try {
        const fieldsValues = await contactsForm.validateFields();

        const contacts = {
            facebook: fieldsValues["facebook"],
            instagram: fieldsValues["instagram"],
            alternateEmail: fieldsValues["alternateEmail"],
            linkedIn: fieldsValues["linkedIn"],
            phone: fieldsValues["phone"],
            twitter: fieldsValues["twitter"],
            youTube: fieldsValues["youTube"],
            website: fieldsValues["website"]
        };

        const formData = new FormData();

        if (fieldsValues.upload.length) {
            formData.append('hasImage', true);

            if (fieldsValues.upload[0].originFileObj)
                formData.append('image', fieldsValues.upload[0].originFileObj);
        } else {
            formData.append('hasImage', false);
        }

        formData.append('name', fieldsValues['name']);
        formData.append('contacts', JSON.stringify(contacts));

        try {
            await axios.post(`${process.env.REACT_APP_API}/business/${businessId}/customers/${customerId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            return true;
        }
        catch(error) {
            if (error.response.status === 409)
                message.error('A customer with this phone already exists');

            return false;
        }
    }
    catch(error) {
        return false;
    }
}

export default saveContacts;