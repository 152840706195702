import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Divider, Form, Input, message, Space, Typography } from 'antd';
import { FcGoogle } from 'react-icons/fc';

import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import UserContext from '../../../contexts/UserContext';
import { requiredNoMessage } from '../../../helpers/form-validators';

const Login = ({switchFormHandler}) => {
    const [loading, setLoading] = useState(false);
    const userContext = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (userContext.user)
            navigate('/account');
    }, [userContext.user]);

    const submitHandler = async values => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/Identity/login`, { email: values.email, password: values.password });
    
            if (response.status == 200) {
                const tokenData = jwtDecode(response.data.token);

                userContext.setUser({
                    email: tokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']
                });

                const redirectTo = location.state?.from || '/account';
                navigate(redirectTo);
            }
        }
        catch(error) {
            if (error.response?.status === 401)
                message.error('Wrong credentials');
        }

        setLoading(false);
    }

    return (
        <React.Fragment>
            <Typography.Title className='auth-box__title' level={3}>Login to your account</Typography.Title>
            <Form name='login' layout='vertical' requiredMark={false} onFinish={submitHandler}>
                <Form.Item label='Email' name='email' rules={[{...requiredNoMessage}]} className='form-item-margin-10'>
                        <Input />
                </Form.Item>
                <Form.Item label='Password' name='password' rules={[{...requiredNoMessage}]}>
                    <Input.Password/>
                </Form.Item>
                <Form.Item className='form-item-margin-10'>
                    <Button type='primary' htmlType='submit' loading={loading} block>Login</Button>
                </Form.Item>
            </Form>
            <Divider className='auth-box__divider' />
            <Button size='medium' icon={<FcGoogle size='20' />} iconPosition='start' block>
                Continue with Google
            </Button>
            <div className='auth-box-footer'>
                <Space>
                    <Typography.Text type='secondary'>Don`t have an account?</Typography.Text>
                    <Button type='link' className='btn-no-padding' onClick={() => switchFormHandler('registration')}>Sign up</Button>
                </Space>
                <Button type='link' className='btn-no-padding' onClick={() => switchFormHandler('forgotPassword')}>Forgot password</Button>
            </div>
            
        </React.Fragment>
    )
}

export default Login;