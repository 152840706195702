import React from "react";

import { Button, Col, Form, Input, InputNumber, Row, Space, Switch, Typography } from "antd";

const EditAttachedEmployees = ({service, currency, form, employees}) => {
    const initialValues = employees.reduce((acc, employee) => {
        acc[`customPrice_${employee.id}`] = employee.customPrice;
        acc[`enabled_${employee.id}`] = employee.enabled;
        return acc;
    }, {});

    return (
        <React.Fragment>
            <Typography.Text
                type="secondary"
                style={{ marginBottom: "20px", display: "block" }}
            >
                Assign employees and set custom prices if needed. If no custom price is specified, the employee will use the default service price which is {service.price} {currency}.
            </Typography.Text>
            <Form
                form={form}
                initialValues={initialValues}
            >
                {employees.map((employee) => (
                    <Row key={employee.id} style={{margin: "5px 0px"}}>
                        <Col span={16}>
                            <Space>
                                <Form.Item style={{margin: 0}} noStyle name={`enabled_${employee.id}`}>
                                    <Switch />
                                </Form.Item>
                                <Typography>{employee.name}</Typography>
                            </Space>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues[`enabled_${employee.id}`] !== currentValues[`enabled_${employee.id}`]}
                            >
                                {({getFieldValue}) => (
                                    <Form.Item name={`customPrice_${employee.id}`} style={{margin: 0}}>
                                        <InputNumber
                                            disabled={!getFieldValue(`enabled_${employee.id}`)}
                                            addonAfter={currency}
                                            min={0}
                                            placeholder="Custom price"
                                        />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                ))}
            </Form>
        </React.Fragment>
    );
};

export default EditAttachedEmployees;