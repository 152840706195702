import { Avatar, Card, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BsClock } from 'react-icons/bs';

const ServicesPage = ({services, currency, employeeId, businessSlug}) => {
    const navigate = useNavigate();

    const handleClick = serviceId => {
        if (employeeId)
            navigate(`/${businessSlug}/slots/${employeeId}/${serviceId}`);
        else
            navigate(`service/${serviceId}`);
    }

    return services?.map(service => (
        <Card key={service.id} className='business-page-card' onClick={() => handleClick(service.id)}>
            <Space size='large'>
                <Avatar size='large' src={service.image}>{service.name.slice(0, 2).toUpperCase()}</Avatar>
                <div>
                    <Typography>{service.name}</Typography>
                    <Space className='business-page-service-details'>
                        <Typography>{`${service.price} ${currency}`}</Typography>
                        <BsClock />
                        <Typography>{service.duration} mins</Typography>
                    </Space>
                </div>
            </Space>
        </Card>
    ));
}

export default ServicesPage;