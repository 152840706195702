import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

import { changeLanguage } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Layout, Menu } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';

import { BsCalendar2Check, BsPeople, BsCartDash, BsPersonGear, BsGear } from 'react-icons/bs';

import Header from './components/Header';

import EmployeeContext from './contexts/EmployeeContext';
import BusinessContext from './contexts/BusinessContext';

import useOperatingEmployee from './hooks/useOperatingEmployee';
import useBusinessSlugData from './hooks/useBusinessSlugData';
import useBusinessRoles from './hooks/useBusinessRoles';
import useCheckMobileScreen from './hooks/useCheckMobileScreen';

const Business = () => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const { businessSlug } = useParams();
    const { t } = useTranslation();

    const business = useBusinessSlugData(businessSlug);
    const employee = useOperatingEmployee(business?.id);
    const roles = useBusinessRoles(business?.id);

    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        if (business)
            changeLanguage(business.translationCode)
    }, [business]);

    return employee && (
        <BusinessContext.Provider value={{business, roles}}>
            <EmployeeContext.Provider value={{employee}}>
                <Layout>
                    <Header username={employee.name} />
                    <Layout className='layout-business'>
                        {true && (
                            <Sider
                                breakpoint='lg'
                                collapsible
                                collapsed={collapsed}
                                onCollapse={() => setCollapsed(!collapsed)}
                                theme='light'
                                className={`sider ${isMobile ? ' mobile' : ''}`}
                                collapsedWidth={isMobile ? 0 : undefined}
                            >
                                <Menu selectedKeys={[location.pathname]} className='sider__menu'>
                                    <Menu.Item key={`/manage/${businessSlug}/calendar`} icon={<BsCalendar2Check />}>
                                        <Link to={`/manage/${businessSlug}/calendar`}>Calendar</Link>
                                    </Menu.Item>
                                    <Menu.Item key={`/manage/${businessSlug}/services`} icon={<BsCartDash/>}>
                                        <Link to={`/manage/${businessSlug}/services`}>Services</Link>
                                    </Menu.Item>
                                    <Menu.Item key={`/manage/${businessSlug}/employees`} icon={<BsPersonGear />}>
                                        <Link to={`/manage/${businessSlug}/employees`}>Employees</Link>
                                    </Menu.Item>
                                    <Menu.Item key={`/manage/${businessSlug}/customers`} icon={<BsPeople/>}>
                                        <Link to={`/manage/${businessSlug}/customers`}>Customers</Link>
                                    </Menu.Item>
                                    <Menu.Item key={`/manage/${businessSlug}/settings`} icon={<BsGear/>}>
                                        <Link to={`/manage/${businessSlug}/settings`}>{t('menus.settings')}</Link>
                                    </Menu.Item>
                                </Menu>
                            </Sider>
                        )}
                        <Content className='layout-business__content'>
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            </EmployeeContext.Provider>
        </BusinessContext.Provider>
    )
}

export default Business;