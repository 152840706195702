import { Col, Row, Select } from 'antd';
import dayjs from 'dayjs';

const CalendarHeader = ({ value, onChange }) => {
    const monthOptions = [];
    const yearOptions = [];
    const currentYear = value.year();
    const currentMonth = value.month();
    const today = dayjs();
    const months = value.localeData().months();

    months.forEach((month, index) => {
        if (currentYear > today.year() || index >= today.month())
            monthOptions.push(<Select.Option key={index} value={index}>{month}</Select.Option>);
    });

    for (let i = today.year(); i <= today.year() + 10; i++) {
      yearOptions.push(<Select.Option key={i} value={i}>{i}</Select.Option>);
    }

    return (
        <Row gutter={8} className='calendar-header-row'>
            <Col>
                <Select value={currentYear} onChange={year => onChange(value.clone().year(year))}>
                    {yearOptions}
                </Select>
            </Col>
            <Col>
                <Select value={currentMonth} onChange={month => onChange(value.clone().month(month))}>
                    {monthOptions}
                </Select>
            </Col>
        </Row>
    );
};

export default CalendarHeader;