import EditContacts from "../components/EditContacts";

const customerEditTabsConfig = (forms, data) => [
    {
      key: 1,
      label: 'Contacts',
      children: <EditContacts form={forms.contactsForm} contacts={data.contacts} customer={data.customer} />
    },
    {
      key: 2,
      label: 'Bookings',
      children: "Not implemented yet"
    },
    {
      key: 3,
      label: 'Notes',
      children: "Not implemented yet"
    },
    {
      key: 4,
      label: 'Statistics',
      children: "Not implemented yet"
    }
];

export default customerEditTabsConfig;