import { Col, Divider, Form, Input, message, Row, Typography, Upload } from "antd";
import { validateFileUpload } from "../../../helpers/common";

const EditContacts = ({form, contacts, employee}) => {
    const upload = Form.useWatch('upload', form);

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                name: employee.name,
                facebook: contacts.facebook,
                instagram: contacts.instagram,
                alternateEmail: contacts.alternateEmail,
                linkedIn: contacts.linkedIn,
                phone: contacts.phone,
                twitter: contacts.twitter,
                youTube: contacts.youTube,
                website: contacts.website,
                email: employee.email,
                upload: employee.image ? [{ url: employee.image }] : []
            }}
            requiredMark={false}
        >
            <Row>
                <Col span={8} style={{display: 'flex', width: "100%", alignItems: 'center'}}>
                    <Form.Item
                        noStyle
                        name="upload"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e.fileList}
                    >
                        <Upload
                            beforeUpload={file => {
                                if (validateFileUpload(file, ['image/jpeg', 'image/png'], 1024))
                                    return true;

                                message.error('Please upload a JPEG or PNG file smaller than 1 MB');
                                return Upload.LIST_IGNORE;
                            }}
                            listType="picture-circle"
                            className="avatar-uploader"
                            maxCount={1}
                            customRequest={({ onSuccess }) => onSuccess()}
                        >
                            {!upload?.length ? 'Add image' : null}
                        </Upload>
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item className="form-item-margin-10" label="Name" name="name" rules={[{ required: true }]}>
                        <Input placeholder="eg. Albert"/>
                    </Form.Item>
                    <Form.Item style={{margin: 0}} label="Email" name="email">
                        <Input disabled/>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Typography.Text type="secondary" style={{marginBottom: "20px", display: "block"}}>
                Provide any additional contact information as needed.
            </Typography.Text>
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="Facebook" name="facebook">
                        <Input placeholder="Enter profile url"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="YouTube" name="youTube">
                        <Input placeholder="Enter profile url"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="Instagram" name="instagram">
                        <Input placeholder="Enter profile url"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Twitter" name="twitter">
                        <Input placeholder="Enter profile url"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="LinkedIn" name="linkedIn">
                        <Input placeholder="Enter profile url"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Website" name="website">
                        <Input placeholder="Enter url"/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="Phone" name="phone">
                        <Input placeholder="Enter in any format"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Alternate email" name="alternateEmail">
                        <Input placeholder="Enter email"/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default EditContacts;