import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';

const BookingTimeInfo = ({ startTimeUtc, duration, timezone }) => {
    const startTime = startTimeUtc.tz(timezone);
    const endTime = startTime.add(duration, 'minute');
    const date = startTime.format('DD MMM YYYY');

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Space size="large">
                <Space style={{ display: "flex", lineHeight: 1 }}>
                    <CalendarOutlined style={{ fontSize: "18px" }} />
                    <Typography style={{ fontSize: "16px" }}>{date}</Typography>
                </Space>
                <Space style={{ display: "flex", lineHeight: 1 }}>
                    <ClockCircleOutlined style={{ fontSize: "18px" }} />
                    <Typography style={{ fontSize: "16px" }}>{startTime.format('HH:mm')} - {endTime.format('HH:mm')}</Typography>
                </Space>
            </Space>
        </div>
    )
}

export default BookingTimeInfo;