import React from 'react';
import { Avatar, Tooltip } from 'antd';

const AttachedEmployees = ({ employees }) => (
    <Avatar.Group max={{count: 2, style: { background: "rgba(22, 119, 255, 0.8)" }}}>
        {employees.length ? employees.map(employee => (
            <Tooltip title={employee.name} placement="top">
                <Avatar src={employee.image}>{employee.name.slice(0, 2).toUpperCase()}</Avatar>
            </Tooltip>
        )) : "None yet"}
    </Avatar.Group>
);

export default AttachedEmployees;