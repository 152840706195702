import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import UserContext from '../../../contexts/UserContext';

const AuthenticatedRoute = ({page}) => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (userContext.user === undefined)
            return;

        if (userContext.user === null)
            navigate('/auth', { state: { from: location.pathname }});
    }, [userContext.user])

    return userContext.user && page;
}

export default AuthenticatedRoute;