const timeValidator = (_, value) => {
    const [hours, minutes] = value.split(':').map(Number);
    return (hours >= 0 && hours <= 23) && (minutes >= 0 && minutes <= 59) ? Promise.resolve() : Promise.reject();
};

const passwordValidator = (_, value) => {
    if (!value)
        return Promise.resolve();

    const minLength = 8;
    const hasUppercase = /[A-Z]/;
    const hasLowercase = /[a-z]/;
    const hasDigit = /\d/;
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;"'<>,.?/\\|`~]/;

    const passwordValid = value.length >= minLength && hasUppercase.test(value) &&
        hasLowercase.test(value) && hasDigit.test(value) && hasSpecialChar.test(value);

    return passwordValid ? Promise.resolve() : Promise.reject('Password must be at least 8 characters long, ' + 
        'contain at least one uppercase/lowercase letter, one digit, and one special character.');
};

const requiredNoMessage = { required: true, message: '' };

export { timeValidator, passwordValidator, requiredNoMessage }