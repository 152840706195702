import { Avatar, Button, Col, Form, Input, message, Modal, Row, Select, Spin, Tag, Typography } from 'antd';
import useEmployeeServiceListing from '../../../employees/hooks/useEmployeeServiceListing';
import { requiredNoMessage } from '../../../../helpers/form-validators';
import { debounce } from 'lodash';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, { useEffect, useState } from 'react';
import AddCustomer from '../../../customers/components/AddCustomer';
import useCustomerSearch from '../../../customers/hooks/useCustomerSearch';

import BookingTimeInfo from './BookingTimeInfo';
import axios from 'axios';

dayjs.extend(utc);
dayjs.extend(timezone);

const BookingModal = ({open, closeHandler, refetch, slot, business, employee, edit}) => {
    const [createCustomerModal, setCreateCustomerModal] = useState();
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const serviceId = Form.useWatch('service', form);
    const duration = Form.useWatch('duration', form);

    const services = useEmployeeServiceListing(business.id, employee.id);
    const customers = useCustomerSearch(business.id);

    const { confirm } = Modal;

    useEffect(() => {
        if (edit) {
            const start = dayjs(edit.start);
            const end = dayjs(edit.end);
            form.setFieldsValue({
                customer: edit.customer?.id,
                service: edit.serviceId,
                price: edit.price,
                duration: end.diff(start, 'minute')
            });
        }    
    }, [services.services]);

    useEffect(() => {
        const service = services.services?.find(x => x.id === serviceId);
    
        if (service && !edit) {
            form.setFieldsValue({ price: service.price });

            if (slot.slots.length > 2) {
                const start = dayjs(slot.start);
                const end = dayjs(slot.end);
                form.setFieldsValue({ duration: end.diff(start, 'minute') });
            } else {
                form.setFieldsValue({ duration: service?.duration });
            }
        }
    }, [serviceId])

    const start = edit ? edit.start : slot.start;
    const startTimeUtc = dayjs.utc(start);
    const endTimeUtc = startTimeUtc.add(duration, 'minute');

    const handleSubmit = async values => {
        setLoading(true);

        try {
            await axios.post(`${process.env.REACT_APP_API}/Business/${business.id}/appointments/calendar`, {
                appointmentId: edit?.appointmentId,
                employeeId: employee.id,
                serviceId: values.service,
                customerId: values.customer,
                price: values.price,
                start: startTimeUtc,
                end: endTimeUtc
            });

            message.success(edit ? 'Appointment was updated' : 'Reservation was created');
            closeHandler();
            refetch();
        }
        catch(error) {
            if (error.response.status === 409)
                message.warning('Oops! That time slot was just taken or overlaps with an existing appointment.');
        }

        setLoading(false);
    }

    const searchCustomers = debounce(value => customers.setSearch(value), 500);

    const handleDeletion = async () => {
        confirm({
            title: `Confirm Deletion`,
            content: `Are you sure you want to delete this appointment?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    await axios.delete(`${process.env.REACT_APP_API}/Business/
                        ${business.id}/appointments/${edit.appointmentId}`);
        
                    message.success('Appointment was deleted');
                    closeHandler();
                    refetch();
                }
                catch(error) {}
            }
        });
    }

    const handleNotAttended = async () => {
        confirm({
            title: `Confirm No-Show Status`,
            content: `Are you sure you want to mark this appointment as missed?
                The customer will be warned and restricted if this continues.`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    await axios.patch(`${process.env.REACT_APP_API}/Business/` +
                        `${business.id}/appointments/${edit.appointmentId}/not-attended`);
        
                    message.success('Appointment was changed in no-show status');
                    closeHandler();
                    refetch();
                }
                catch(error) {}
            }
        });
    }
    
    return (
        <Modal
            title='New appointment'
            open={open}
            onCancel={closeHandler}
            maskClosable={false}
            footer={[
                edit && <Button key="no-show" onClick={handleNotAttended}>No-show</Button>,
                edit && <Button key="delete" danger onClick={handleDeletion}>Delete</Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
                    {edit ? 'Update' : 'Create'}
                </Button>
            ]}
        >
            <Spin spinning={services.loading}>
                <div className="booking-confirmation-modal">
                    <Avatar size={70} src={employee.image}>
                        {employee.name.slice(0, 2).toUpperCase()}
                    </Avatar>
                    <Typography.Text className="booking-confirmation-modal__heading">Appointment with {employee.name}</Typography.Text>
                    <Form className='calendar-employee-appointment-form' layout='vertical' requiredMark={false}  form={form} onFinish={handleSubmit}>
                        <Form.Item rules={[{...requiredNoMessage}]} name='service' className='form-item-margin-10'>
                            <Select placeholder="Select a service">
                                {services.services?.map(service => <Select.Option value={service.id}>{service.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Row gutter={10}>
                            {serviceId && (
                                <React.Fragment>
                                    <Col span={12}>
                                        <Form.Item noStyle shouldUpdate={(prev, curr) => prev['service'] !== curr['service']}>
                                            <Form.Item name="price" className='form-item-margin-10' rules={[{...requiredNoMessage}]}>
                                                <Input placeholder="Enter price" suffix={business.currency} />
                                            </Form.Item>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item noStyle shouldUpdate={(prev, curr) => prev['service'] !== curr['service']}>
                                            <Form.Item name="duration" className='form-item-margin-10' rules={[{...requiredNoMessage}]}>
                                                <Input placeholder="Enter duration" suffix='mins' />
                                            </Form.Item>
                                        </Form.Item>
                                    </Col>
                                </React.Fragment>
                            )}
                            
                            <Col span={24}>
                            <Form.Item rules={[{...requiredNoMessage}]} name='customer' className='form-item-margin-10'>
                                <Select
                                    showSearch
                                    placeholder="Search a customer by name or phone"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                    notFoundContent={
                                        <a onClick={() => setCreateCustomerModal(true)}>Create new customer</a>
                                      }
                                    onSearch={searchCustomers}
                                >
                                    {edit && edit.customer && (
                                        <Select.Option value={edit.customer.id}>
                                            {`${edit.customer.name}, ${edit.customer.phone}`}
                                        </Select.Option>
                                    )}
                                    {customers.data?.map(customer =>
                                        <Select.Option value={customer.id}>
                                            {`${customer.name}, ${customer.phone}`}
                                        </Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {duration && (
                        <BookingTimeInfo startTimeUtc={startTimeUtc} duration={duration} timezone={business.timezone} />
                    )}
                </div>
            </Spin>
            {createCustomerModal && (
                <AddCustomer
                    open={Boolean(createCustomerModal)}
                    closeHandler={() => setCreateCustomerModal()}
                    businessId={business.id}
                    refetch={() => {}}
                />
            )}
        </Modal>
    )
}

export default BookingModal;