import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useCustomerSearch = (businessId) => {
    const [data, setData] = useState();
    const [search, setSearch] = useState();
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async () => {
        setLoading(true);

        const response = await axios.get(`${process.env.REACT_APP_API}/business/
            ${businessId}/customers/search?search=${search}`);

            setData(response.data);
        setLoading(false);
    }, [businessId, search]);

    useEffect(() => {
        if (businessId && search)
            fetch();
    }, [businessId, fetch]);

    return { data, loading, setSearch, refetch: fetch };
};

export default useCustomerSearch;