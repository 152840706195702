import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useServices = (businessId) => {
    const [services, setServices] = useState({ total: 0, records: [] });
    const [pagination, setPagination] = useState({ page: 1, size: 5 });
    const [filters, setFilters] = useState({ search: null });
    const [loading, setLoading] = useState(false);

    const fetchServices = useCallback(async () => {
        setLoading(true);
        const params = { page: pagination.page, size: pagination.size };

        if (filters.search)
            params.search = filters.search;

        const response = await axios.get(`${process.env.REACT_APP_API}/business/${businessId}/services`, {params});

        setServices(response.data);
        setLoading(false);
    }, [businessId, pagination, filters]);

    useEffect(() => {
        if (businessId)
            fetchServices();
    }, [businessId, pagination, fetchServices]);

    return { services, loading, pagination, setPagination, setFilters, refetch: fetchServices };
};

export default useServices;