const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);
const validateFileUpload = (file, types, size) => types.includes(file.type) && file.size / 1024 < size;

const redirect = (url, newTab = false) => {
    if (newTab)
        window.open(url, '_blank');
    else
        window.location.replace(url);
}

export { capitalize, validateFileUpload, redirect }