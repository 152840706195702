import { useState } from 'react';

import { Col, Form, Input, message, Modal, Row, Select, Space, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';

import { requiredNoMessage } from '../../../helpers/form-validators';
import useIndustries from '../../../hooks/useIndustries';
import useCurrencies from '../../../hooks/useCurrencies';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import useLanguages from '../../../hooks/useLanguages';

const CreateBusiness = ({open, closeHandler, refetch}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const industries = useIndustries();
    const currencies = useCurrencies();
    const languages = useLanguages();
    const isMobile = useCheckMobileScreen();

    const colSize = isMobile ? 24 : 12;

    const submitHandler = async values => {
        setLoading(true);

        try {
            await axios.post(`${process.env.REACT_APP_API}/business`, {
                name: values.name,
                industry: values.industry,
                currency: values.currency,
                timezone: values.timezone,
                address: values.address,
                language: values.language,
                slug: values.slug
            });

            message.success(`Successfully created business ${values.name}`);
            refetch();
            closeHandler();
        }
        catch(error) {
            if (error.response?.status === 409) {
                if (error.response.data === 'SlugAlreadyUsed')
                    message.warning('This slug is already in use by another business');
            }
        }

        setLoading(false);
    }

    return (
        <Modal
            title='Create new business'
            okButtonProps={{loading}}
            open={open}
            okText='Create'
            onOk={() => form.submit()}
            onCancel={closeHandler}
        >
            <Typography.Text type='secondary'>Start with the basics now - complete your vision later</Typography.Text>
            <Form
                style={{marginTop: '20px'}}
                form={form}
                name='add-employee'
                layout='vertical'
                requiredMark={false}
                onFinish={submitHandler}
            >
                <Row gutter={10}>
                    <Col span={colSize}>
                        <Form.Item 
                            label='Name'
                            name='name'
                            rules={[{... requiredNoMessage}]}
                            className='form-item-margin-10'
                            validateTrigger='submit'
                        >
                            <Input placeholder='Enter your business name' />
                        </Form.Item>
                    </Col>
                    <Col span={colSize}>
                        <Form.Item
                            label='Industry'
                            name='industry'
                            rules={[ { required: true, message: '' }]}
                            className='form-item-margin-10'
                        >
                            <Select showSearch placeholder='Select industry'>
                                {industries.data?.map(industry => (
                                    <Select.Option key={industry.id} value={industry.id}>{industry.name}</Select.Option> 
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={colSize}>
                        <Form.Item
                            label='Currency'
                            name='currency'
                            rules={[ { required: true, message: '' }]}
                            className='form-item-margin-10'
                        >
                            <Select showSearch placeholder='Select currency'>
                                {currencies.data?.map((currency, index) => (
                                    <Select.Option key={index} value={currency.name}>
                                        {`${currency.name} (${currency.description})`}
                                    </Select.Option> 
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={colSize}>
                        <Form.Item label='Timezone' name='timezone' rules={[{...requiredNoMessage}]} className='form-item-margin-10'>
                            <Select showSearch placeholder='Select timezone'>
                                {moment.tz.names().map((timezone, index) => (
                                    <Select.Option key={index} value={timezone}>{timezone}</Select.Option> 
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={colSize}>
                        <Form.Item label='Address' name='address' rules={[{...requiredNoMessage}]} className='form-item-margin-10'>
                            <Input placeholder='Enter physical address' />
                        </Form.Item>
                    </Col>
                    <Col span={colSize}>
                        <Form.Item label='Language' name='language' rules={[{...requiredNoMessage}]} className='form-item-margin-10'>
                            <Select showSearch placeholder='Select language'>
                                {languages.data?.map(language => (
                                    <Select.Option key={language.id} value={language.id}>{language.name}</Select.Option> 
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={(
                                <Space>
                                    Slug
                                    <Tooltip title='Pick a short, clear slug that customers will use to find your business online'>
                                        <InfoCircleOutlined  />
                                    </Tooltip>
                                </Space>
                            )}
                            name='slug'
                            rules={[{...requiredNoMessage}]}
                        >
                            <Input prefix={`${window.location.origin}/`} placeholder='e.g., mihaela-nails' />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default CreateBusiness;