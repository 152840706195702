import axios from "axios";

const saveAttachedServices = async (serviceId, businessId, attachedServicesForm) => {
    try {
        const fieldsValues = await attachedServicesForm.validateFields();
        const services = [];

        Object.entries(fieldsValues).forEach(([key, value]) => {
            const [field, id] = key.split("_");
            let service = services.find(x => x.id === id);
            
            if (service)
                service[field] = value;
            else
                services.push({ id, [field]: value });
        });

        await axios.put(`${process.env.REACT_APP_API}/business/${businessId}/employees/${serviceId}/attached-services`, { services });
        return true;
    }
    catch(error) {
        return false;
    }
}

export default saveAttachedServices;