import EditContacts from "../components/EditContacts";
import EditWorkingHours from "../components/EditWorkingHours";
import EditBreaks from "../components/EditBreaks";
import EditAttachedServices from "../components/EditAttachedServices";
import EditTimeOff from "../components/EditTimeOff";

const employeeEditTabsConfig = (forms, data, currency, timezone, businessId) => [
    {
      key: 1,
      label: 'Contacts',
      children: <EditContacts form={forms.contactsForm} contacts={data.contacts} employee={data.employee} />
    },
    {
      key: 2,
      label: 'Working hours',
      children: <EditWorkingHours form={forms.workingHoursForm} workingHours={data.workingHours} />
    },
    {
      key: 3,
      label: 'Breaks',
      children: <EditBreaks form={forms.breaksForm} breaks={data.breaks} />,
    },
    {
        key: 4,
        label: 'Time off',
        children: <EditTimeOff form={forms.timeOffsForm} timeOffs={data.timeOffs} timezone={timezone} businessId={businessId} />,
    },
    {
      key: 5,
      label: 'Services',
      children: <EditAttachedServices form={forms.attachedServicesForm} services={data.services} currency={currency} />,
  }
];

export default employeeEditTabsConfig;