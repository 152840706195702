import { Badge, Typography } from "antd";
import { utcDateTimeToTimezone } from "../../../helpers/parsers";

const PastTimeOff = ({timeOffs, timezone}) => {

    return timeOffs.map((timeOff) => {
        const showTime = timeOff.requiresTime;
        const from = utcDateTimeToTimezone(timeOff.start, timezone, showTime);
        const to = utcDateTimeToTimezone(timeOff.end, timezone, showTime);

        return (
            <Badge.Ribbon text={timeOff.type}>
                <Typography.Text style={{display: "block", borderRadius: "5px", padding: "10px", margin: "5px", background: "#fafafa", border: "1px solid #f5f5f5"}}>
                    {from} - {to}
                </Typography.Text>
            </Badge.Ribbon>
        )
    })
}

export default PastTimeOff;