import React, { useContext, useState } from 'react';
import { Modal } from 'antd';

import customerDeleteModalConfig from '../features/customers/configs/customer-delete-modal';
import useCustomers from '../features/customers/hooks/useCustomers';
import AddCustomer from '../features/customers/components/AddCustomer';
import customerTableConfig from '../features/customers/configs/customer-table';
import ManageCustomer from '../features/customers/components/ManageCustomer';

import TableViewPage from '../components/TableViewPage';
import BusinessContext from '../contexts/BusinessContext';

const CustomerPage = () => {
    const [addCustomerModal, setAddCustomerModal] = useState(false);
    const [customerDetailsDrawer, setCustomerDetailsDrawer] = useState({ open: false, customer: null });

    const businessContext = useContext(BusinessContext);
    const businessId = businessContext.business.id;

    const { customers, loading, pagination, setPagination, setFilters, refetch } = useCustomers(businessId);
    const { confirm } = Modal;

    const onEdit = customer => setCustomerDetailsDrawer({ open: true, customer });
    const onDelete = customer => confirm(customerDeleteModalConfig(customer, businessId, refetch))

    return (
        <React.Fragment>
            <TableViewPage
                title='Customers'
                description='Add and manage everything about your customers.'
                search={{
                    loading: loading,
                    placeholder: 'Search by name or email',
                    handler: search => setFilters(prev => ({...prev, search: search}))
                }}
                add={{
                    text: 'Add Customer',
                    handler: () => setAddCustomerModal(true)
                }}
                table={{
                    columns: customerTableConfig(onEdit, onDelete),
                    data: customers,
                    loading: loading
                }}
                pagination={pagination}
                setPagination={setPagination}
                business={businessContext.business}
            />
            {customerDetailsDrawer.open && (
                <ManageCustomer
                    open={customerDetailsDrawer.open}
                    closeHandler={() => setCustomerDetailsDrawer({ open: false, customer: null })}
                    businessId={businessId}
                    currency={businessContext.business.currency}
                    refetch={refetch}
                    customer={customerDetailsDrawer.customer}
                />
            )}
            {addCustomerModal && (
                <AddCustomer
                    open={addCustomerModal}
                    closeHandler={() => setAddCustomerModal(false)}
                    businessId={businessId}
                    refetch={refetch}
                />
            )}
        </React.Fragment>
    );
};

export default CustomerPage;