import { useEffect } from 'react';
import { message } from 'antd';
import axios from 'axios';

const useAxiosInterceptors = setUser => {
    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            config => {
                config.withCredentials = true;
                return config;
            },
            error => Promise.reject(error)
        );
    
        const responseInterceptor = axios.interceptors.response.use(
            response => response,
            error => {
                switch(error.response?.status) {
                    case 401:
                        setUser(null);
                        break;
                    case 403: 
                        if (!error.config.headers["disable-forbidden-message"])
                            message.error("You have no access for this operation");
                        break;
                    case 409: case 400: break;
                    default: message.error("Error occured, try again later.");
                }

                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, []);
}

export default useAxiosInterceptors;