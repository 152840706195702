import { Avatar, Col, Form, Input, message, Modal, Row, Space, Tag, Typography } from "antd";
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const BookingModal = ({open, booking, closeHandler}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const handleConfirmation = async values => {
        setLoading(true);

        try {
            await axios.post(`${process.env.REACT_APP_API}/Business/${booking.business.id}/appointments`, {
                employeeId: booking.employee.id,
                serviceId: booking.service.id,
                date: booking.date.startOf('day').format('YYYY-MM-DD'),
                slot: booking.slot,
                name: values.name,
                phone: values.phone,
                email: values.email
            });

            message.success('Your reservation was created successfully!');
            navigate(`/${booking.business.slug}`);
        }
        catch(error) {
            if (error.response.status === 409)
                message.warning('Oops! That slot was just taken. Pick a different time?');
        }

        setLoading(false);
    }

    return (
        <Modal
            title='Booking summarization'
            okButtonProps={{loading}}
            open={open}
            okText='Confirm'
            onOk={() => form.submit()}
            onCancel={closeHandler}
            maskClosable={false}
        >
            <div className="booking-confirmation-modal">
                <Avatar size={70} src={booking.employee.image}>
                    {booking.employee.name.slice(0, 2).toUpperCase()}
                </Avatar>
                <Typography.Text className="booking-confirmation-modal__heading">Appointment with {booking.employee.name}</Typography.Text>
                <Tag className="booking-confirmation-modal__tag">
                    {booking.service.name}
                </Tag>
                <Typography style={{marginTop: "20px", marginBottom: "10px"}}>Your appointment date is</Typography>
                <Space>
                    <Tag className="booking-confirmation-modal__date-tag" icon={<CalendarOutlined />}>
                        {booking.date.format("DD MMM YYYY")}
                    </Tag>
                    <Tag className="booking-confirmation-modal__date-tag" icon={<ClockCircleOutlined />}>
                        {booking.slot}
                    </Tag>
                </Space>
                <Typography.Text type="secondary" className="booking-confirmation-modal__secondary">
                    {`Your ${booking.service.duration}-minute appointment costs 
                    ${booking.service.price} ${booking.business.currency}`}
                </Typography.Text>
                <Form requiredMark={false} layout="vertical" form={form} onFinish={handleConfirmation}>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item className='booking-confirmation-field' name='name' rules={[{ required: true, message: 'Please enter name' }]}>
                                <Input placeholder="Your name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className='booking-confirmation-field' name='phone' rules={[{ required: true, message: 'Please enter phone' }]}>
                                <Input placeholder="Your phone" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item className='booking-confirmation-field' name='email'>
                                <Input placeholder="Your email (optional)" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}

export default BookingModal;