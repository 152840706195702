import { Collapse } from "antd";
import Icon, { DownCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import BusinessInfo from "./BusinessInfo";

const CollapseMobile = ({businessHours, businessData}) => {
    return (
        <Collapse
            ghost
            className='business-page-mobile-collapse'
            expandIcon={({ isActive }) => (
                <Icon
                    component={isActive ? DownCircleOutlined : RightCircleOutlined}
                    className='business-page-mobile-collapse__icon'
                />
            )}
            items={[{ key: 1, children: <BusinessInfo businessData={businessData} businessHours={businessHours} /> }]}
        />
    )
}

export default CollapseMobile;