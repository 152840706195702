import { Col, Form, Input, message, Modal, Row, Select, Space, Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons"; // import the InfoCircleOutlined icon

import { useContext, useEffect, useState } from "react";
import axios from "axios";
import BusinessContext from "../../../contexts/BusinessContext";
import roleDescription from "../../../helpers/role-description";

const AddEmployee = ({open, closeHandler, businessId, refetch}) => {
    const [loading, setLoading] = useState(false);
    const businessContext = useContext(BusinessContext);

    const [form] = Form.useForm();

    const submitHandler = async values => {
        setLoading(true);

        try {
            await axios.post(`${process.env.REACT_APP_API}/Business/${businessId}/employees`, {
                email: values.email, name: values.name, roleId: values.role
            });

            message.success("An invitation email has been successfully sent");
            refetch();
            closeHandler();
        }
        catch(error) {
            if (error.response.status === 409)
                message.error("An employee with this email or name already exists");
        }

        setLoading(false);
    }

    return (
        <Modal title="Add employee" okButtonProps={{loading}} open={open} okText="Send Invitation" onOk={() => form.submit()} onCancel={closeHandler}>
            <Typography.Text type="secondary">Send an invitation to an employee to join your business.</Typography.Text>
            <Form style={{marginTop: "20px"}} form={form} name="add-employee" layout="vertical" requiredMark={false} onFinish={submitHandler}>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item 
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: '' },
                                { type: 'email', message: 'Please enter a valid email address' }
                            ]}
                            className="form-item-margin-10"
                            validateTrigger="submit"
                        >
                                <Input placeholder="Enter an email address" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[ { required: true, message: '' }]}
                            className="form-item-margin-10"
                        >
                            <Input placeholder="Enter a name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Access level"
                            name="role"
                            rules={[{ required: true, message: '' }]}

                        >
                            <Select placeholder="Select an access level">
                                {businessContext.roles.map(role => (
                                    <Select.Option key={role.id} value={role.id}>
                                        {roleDescription.hasOwnProperty(role.name) ? (
                                            <Tooltip placement="left" title={roleDescription[role.name]}>
                                                <Space style={{width: "100%"}}>
                                                    <InfoCircleOutlined />
                                                    {role.name}
                                                </Space>
                                            </Tooltip>
                                        ) : role.name}
                                    </Select.Option> 
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default AddEmployee;