import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const useServiceEmployeeListing = (businessId, service) => {
    const [employees, setEmployees] = useState();
    const [loading, setLoading] = useState(false);

    const fetchDetails = useCallback(async () => {
        if (!businessId)
            return;

        setLoading(true);
        
        var response = await axios.get(
            `${process.env.REACT_APP_API}/business/${businessId}/services/${service}/employee-listing`);

            setEmployees(response.data);
        setLoading(false);
    }, [businessId, service]);

    useEffect(() => {
        if (service != null)
            fetchDetails();
    }, [service, fetchDetails]);

    return { employees, loading };
};

export default useServiceEmployeeListing;