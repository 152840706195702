import axios from "axios";
import moment from "moment-timezone"; // Ensure this is imported

const saveTimeOffs = async (employeeId, businessId, timezone, form) => {
    try {
        const fieldsValues = await form.validateFields();

        console.log(fieldsValues);

        const formattedValues = fieldsValues.timeOffs.map(x => ({
            id: x.id,
            start: moment(x.start).tz(timezone, true).utc(),
            end: moment(x.end).tz(timezone, true).utc(),
            type: x.type
        }));
        
        await axios.put(
            `${process.env.REACT_APP_API}/business/${businessId}/employees/${employeeId}/update-leaves`,
            {leaves: formattedValues});

        return true;
    }
    catch(error) {
        console.log(error);
        return false;
    }
}

export default saveTimeOffs;