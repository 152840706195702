import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useService = (serviceId, businessId) => {
    const [service, setService] = useState();
    const [loading, setLoading] = useState(false);

    const fetchService = useCallback(async () => {
        setLoading(true);

        const response = await axios.get(
            `${process.env.REACT_APP_API}/business/${businessId}/services/${serviceId}/simple`);

        setService(response.data);
        setLoading(false);
    }, [businessId, serviceId]);

    useEffect(() => {
        if (businessId)
            fetchService();
    }, [businessId, serviceId, fetchService]);

    return { service, loading };
};

export default useService;