import axios from "axios";

const saveAttachedEmployees = async (serviceId, businessId, attachedEmployeesForm) => {
    try {
        const fieldsValues = await attachedEmployeesForm.validateFields();
        const employees = [];

        Object.entries(fieldsValues).forEach(([key, value]) => {
            const [field, id] = key.split("_");
            let employee = employees.find(emp => emp.id === id);

            if (employee)
                employee[field] = value;
            else
                employees.push({ id, [field]: value });
        });

        await axios.put(`${process.env.REACT_APP_API}/business/${businessId}/services/${serviceId}/attached-employees`, { employees });
        return true;
    }
    catch(error) {
        return false;
    }
}

export default saveAttachedEmployees;