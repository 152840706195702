import { Avatar, Typography } from "antd"
import Sider from "antd/es/layout/Sider"
import BusinessInfo from "./BusinessInfo";
import ReviewsSummary from "./ReviewsSummary";

const SiderDesktop = ({businessData, businessHours, brandColor}) => {
    return (
        <Sider className='booking-page-sider' style={{ backgroundColor: '#f5f5f5' }}>
            <Avatar className='booking-page-sider__logo' src={businessData?.logo} />
            <Typography.Title level={4} className='booking-page-sider__title' style= {{ color: brandColor }}>
                {businessData?.name}
            </Typography.Title>
            {businessData?.reviewSummary && <ReviewsSummary reviewSummary={businessData.reviewSummary} />}
            <BusinessInfo businessData={businessData} businessHours={businessHours} />
        </Sider>
    )
}

export default SiderDesktop;