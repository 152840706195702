import React, { useEffect, useState } from "react";
import { Drawer, Button, Space, Tabs, Form, message } from "antd";
import serviceEditTabsConfig from '../configs/service-edit-tabs';
import useServiceDetails from "../hooks/useServiceDetails";
import saveTabsFactory from "../../services/save-form-methods/save-tabs-factory";

const ManageService = ({service, businessId, currency, refetch, open, closeHandler}) => {
    const [tab, setTab] = useState(1);
    const [modifiedData, setModifiedData] = useState({});

    const [detailsForm] = Form.useForm();
    const [attachedEmployeesForm] = Form.useForm();

    const { data, loading } = useServiceDetails(businessId, service.id);

    useEffect(() => {
        if (data)
            setModifiedData(({...data, service: service}))
    }, [data, service]);

    const changeTabHandler = tab => {
        setTab(tab);
        resetFields();
    }

    const resetFields = () => {
        detailsForm.resetFields();
        attachedEmployeesForm.resetFields();
    }

    const tabSaveMapping = saveTabsFactory(service.id, businessId, { detailsForm, attachedEmployeesForm });

    const saveHandler = async () => {
        if(await tabSaveMapping[tab]()) {
            refetch();
            closeHandler();
            message.success(`Successfully updated ${service.name}`);
        }
    }

    const tabsConfig = modifiedData && serviceEditTabsConfig(service, currency, { detailsForm, attachedEmployeesForm }, modifiedData);

    return (
        <Drawer
            title={`Details for ${service.name}`}
            loading={loading}
            width={600}
            open={open}
            onClose={closeHandler}
            extra={(
                <Space>
                    <Button onClick={resetFields} type="default">Reset</Button>
                    <Button onClick={saveHandler} type="primary">Save</Button>
                </Space>
            )}
        >
            {modifiedData && <Tabs items={tabsConfig} onChange={changeTabHandler} />}
        </Drawer>
    )
}

export default ManageService;