import { useContext } from 'react';
import { Button, Dropdown } from 'antd';
import { SettingOutlined, LogoutOutlined, DownOutlined  } from '@ant-design/icons';
import UserContext from '../contexts/UserContext';

const UserDropdownMenu = ({name}) => {
    const userContext = useContext(UserContext);

    const items = [
        {
            key: '1',
            label: "Settings",
            icon: <SettingOutlined />
        },
        {
            type: "divider"
        },
        {
            key: '2',
            label: <div onClick={() => userContext.setUser(null)}>Logout</div>,
            icon: <LogoutOutlined />,
            danger: true
        }
    ];

    return (
        <Dropdown placement="bottom" arrow={{pointAtCenter: true}} menu={{items}}>
            <Button type="text" iconPosition='end' icon={<DownOutlined  />}>{name}</Button>
        </Dropdown>
    )
}

export default UserDropdownMenu;