import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useEmployeeAppointments = (employeeId, businessId) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(async () => {
        setLoading(true);

        const response = await axios.get(`${process.env.REACT_APP_API}/business/${businessId}
            /appointments/calendar?employeeId=${employeeId}`);

        setData(response.data);
        setLoading(false);
    }, [employeeId, businessId]);

    useEffect(() => {
        if (employeeId)
            fetch();
    }, [employeeId, businessId, fetch]);

    return { data, loading, refetch: fetch };
};

export default useEmployeeAppointments;