import axios from "axios";

const saveDetails = async (serviceId, businessId, attachedEmployeesForm) => {
    try {
        const fieldsValues = await attachedEmployeesForm.validateFields();
        const formData = new FormData();

        if (fieldsValues.upload.length) {
            formData.append('hasImage', true);

            if (fieldsValues.upload[0].originFileObj)
                formData.append('image', fieldsValues.upload[0].originFileObj);
        } else {
            formData.append('hasImage', false);
        }

        formData.append('name', fieldsValues["name"]);
        formData.append('price', fieldsValues["price"]);
        formData.append('duration', fieldsValues["duration"]);
        formData.append('visibility', fieldsValues["visibility"]);

        if (fieldsValues["priorBuffer"])
            formData.append('priorBuffer', fieldsValues["priorBuffer"]);

        await axios.put(`${process.env.REACT_APP_API}/business/${businessId}/services/${serviceId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

        return true;
    }
    catch(error) {
        return false;
    }
}

export default saveDetails;