import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const useCustomerDetails = (businessId, customer) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const fetchDetails = useCallback(async () => {
        setLoading(true);
        var response = await axios.get(`${process.env.REACT_APP_API}/business/${businessId}/customers/${customer}`);

        setData({
            contacts: response.data.contacts
        });

        setLoading(false);
    }, [businessId, customer]);

    useEffect(() => {
        if (customer != null)
            fetchDetails();
    }, [customer, fetchDetails]);

    return { data, loading };
};

export default useCustomerDetails;