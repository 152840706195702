import { capitalize } from "./common";

const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

const getActiveWorkingHours = values => {
    const workingHours = [];

    daysOfWeek.forEach(day => {
        if (values[`${day}Switch`]) {
            workingHours.push({
                dayOfWeek: capitalize(day),
                start: values[`${day}Start`],
                end: values[`${day}End`]
            });
        }
    });
    
    return workingHours;
}

export { daysOfWeek, getActiveWorkingHours }