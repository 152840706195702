import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, message, Space, Tabs } from "antd";
import saveTabsFactory from "../save-form-methods/save-tabs-factory";
import employeeEditTabsConfig from '../configs/employee-edit-tabs';
import useEmployeeDetails from "../hooks/useEmployeeDetails";

const ManageEmployee = ({employee, businessId, currency, timezone, refetch, open, closeHandler}) => {
    const [tab, setTab] = useState(1);
    const [modifiedData, setModifiedData] = useState();

    const [contactsForm] = Form.useForm();
    const [workingHoursForm] = Form.useForm();
    const [breaksForm] = Form.useForm();
    const [timeOffsForm] = Form.useForm();
    const [attachedServicesForm] = Form.useForm();

    const { data, loading } = useEmployeeDetails(businessId, employee.id);

    useEffect(() => {
        if (data)
            setModifiedData(({...data, employee: employee}))
    }, [data, employee]);

    const changeTabHandler = tab => {
        setTab(tab);
        resetFields();
    }

    const resetFields = () => {
        contactsForm.resetFields();
        workingHoursForm.resetFields();
        breaksForm.resetFields();
        timeOffsForm.resetFields();
        attachedServicesForm.resetFields();
    }

    const tabSaveMapping = saveTabsFactory(
        employee.id, businessId, timezone, { contactsForm, workingHoursForm, breaksForm, timeOffsForm, attachedServicesForm });

    const saveHandler = async () => {
        if(await tabSaveMapping[tab]()) {
            refetch();
            closeHandler();
            message.success(`Successfully updated ${employee.name}`);
        }
    }

    const tabsConfig = modifiedData && employeeEditTabsConfig(
        {contactsForm, workingHoursForm, breaksForm, timeOffsForm, attachedServicesForm}, modifiedData, currency, timezone, businessId);

    return (
        <Drawer
            title={`Details for ${employee.name}`}
            loading={loading}
            width={600}
            open={open}
            onClose={closeHandler}
            extra={(
                <Space>
                    <Button onClick={resetFields} type="default">Reset</Button>
                    <Button onClick={saveHandler} type="primary">Save</Button>
                </Space>
            )}
        >
            {modifiedData && <Tabs items={tabsConfig} onChange={changeTabHandler} />}
        </Drawer>
    )
}

export default ManageEmployee;