import { useState } from 'react';
import { Col, Form, Input, message, Modal, Row, Typography } from 'antd';
import axios from 'axios';

const AddCustomer = ({open, closeHandler, businessId, refetch}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const submitHandler = async values => {
        setLoading(true);

        try {
            await axios.post(`${process.env.REACT_APP_API}/Business/${businessId}/customers`, {
                email: values.email, name: values.name, phone: values.phone
            });

            message.success('Customer successfully created');
            refetch();
            closeHandler();
        }
        catch(error) {
            if (error.response.status === 409)
                message.error('A customer with this email or phone already exists');
        }

        setLoading(false);
    }

    return (
        <Modal title='Add customer' okButtonProps={{loading}} open={open} okText='Add Customer' onOk={() => form.submit()} onCancel={closeHandler}>
            <Typography.Text type='secondary'>Provide name, phone and (optional) email for reminder notifications.</Typography.Text>
            <Form style={{marginTop: '20px'}} form={form} layout='vertical' requiredMark={false} onFinish={submitHandler}>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label='Name'
                            name='name'
                            rules={[ { required: true, message: '' }]}
                            className='form-item-margin-10'
                        >
                            <Input placeholder='Enter name' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item 
                            label='Phone'
                            name='phone'
                            rules={[ { required: true, message: '' }]}
                            className='form-item-margin-10'
                            validateTrigger='submit'
                        >
                                <Input placeholder='Enter phone' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item 
                            label='Email'
                            name='email'
                            rules={[
                                { type: 'email', message: 'Please enter a valid email address' }
                            ]}
                            validateTrigger='submit'
                        >
                                <Input placeholder='Enter email' />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default AddCustomer;