import React from 'react';
import { Button, Typography, Pagination, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { SearchOutlined } from '@ant-design/icons';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const TableViewPage = ({title, description, search, add, table, pagination, setPagination}) => {
    const isMobile = useCheckMobileScreen();
    const mobile = isMobile ? 'mobile' : '';

    return (
        <div className={`page ${isMobile ? 'mobile' : ''}`}>
            <div className='page__header'>
                <Typography.Title level={3} className='page-title'>{title}</Typography.Title>
            </div>
            <Typography.Text type="secondary">{description}</Typography.Text>
            <div className={`table-header ${mobile}`}>
                <Search
                    addonBefore={!isMobile && <SearchOutlined />}
                    placeholder={search.placeholder}
                    allowClear
                    enterButton={isMobile ? true : 'Search'}
                    onSearch={search.handler}
                    className={`table-header__search ${mobile}`}
                />
                <Button type="primary" onClick={add.handler}>{add.text}</Button>
            </div>
            <Table
                columns={table.columns}
                dataSource={table.data.records}
                pagination={false}
                rowKey="id"
                size="small"
                loading={table.loading}
                scroll={{ x: 'max-content' }}
            />
            <Pagination
                hideOnSinglePage
                size='small'
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                className='table-pagination'
                align="end"
                total={table.data.total}
                current={pagination.page}
                onChange={page => setPagination(prev => ({...prev, page: page}))}
                pageSize={pagination.size}
            />
        </div>
    );
}

export default TableViewPage;