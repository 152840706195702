import moment from 'moment';

const utcDateTimeToTimezone = (datetime, timezone, time = false) => {
    const local = moment.utc(datetime).tz(timezone);
    let format = 'DD MMM YYYY';

    if (time) format += ' HH:mm';

    return local.format(format);
}

const formatTime = value => {
    const digits = value.replace(/\D/g, '');
    const time = digits.slice(0, 4);
    return time.length <= 2 ? time : `${time.slice(0, 2)}:${time.slice(2, 4)}`;
};


export { utcDateTimeToTimezone, formatTime }