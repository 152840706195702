import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, message, Space, Tabs } from "antd";
import saveTabsFactory from "../save-form-methods/save-tabs-factory";
import customerEditTabsConfig from '../configs/customer-edit-tabs';
import useCustomerDetails from "../hooks/useCustomerDetails";

const ManageCustomer = ({customer, businessId, currency, refetch, open, closeHandler}) => {
    const [tab, setTab] = useState(1);
    const [modifiedData, setModifiedData] = useState();

    const [contactsForm] = Form.useForm();

    const { data, loading } = useCustomerDetails(businessId, customer.id);

    useEffect(() => {
        if (data)
            setModifiedData(({...data, customer: customer}))
    }, [data, customer]);

    const changeTabHandler = tab => {
        setTab(tab);
        resetFields();
    }

    const resetFields = () => {
        contactsForm.resetFields();
    }

    const tabSaveMapping = saveTabsFactory(customer.id, businessId, { contactsForm });

    const saveHandler = async () => {
        if(await tabSaveMapping[tab]()) {
            refetch();
            closeHandler();
            message.success(`Successfully updated ${customer.name}`);
        }
    }

    const tabsConfig = modifiedData && customerEditTabsConfig({contactsForm}, modifiedData);

    return (
        <Drawer
            title={`Details for ${customer.name}`}
            loading={loading}
            width={600}
            open={open}
            onClose={closeHandler}
            extra={(
                <Space>
                    <Button onClick={resetFields} type="default">Reset</Button>
                    <Button onClick={saveHandler} type="primary">Save</Button>
                </Space>
            )}
        >
            {modifiedData && <Tabs items={tabsConfig} onChange={changeTabHandler} />}
        </Drawer>
    )
}

export default ManageCustomer;