import React, { useContext, useState } from 'react';
import { Modal } from 'antd';

import employeeDeleteModalConfig from '../features/employees/configs/employee-delete-modal';
import useEmployees from '../features/employees/hooks/useEmployees';
import AddEmployee from '../features/employees/components/AddEmployee';
import employeeTableConfig from '../features/employees/configs/employee-table';
import ManageEmployee from '../features/employees/components/ManageEmployee';

import TableViewPage from '../components/TableViewPage';
import BusinessContext from '../contexts/BusinessContext';

const EmployeePage = () => {
    const [addEmployeeModal, setAddEmployeeModal] = useState(false);
    const [employeeDetailsDrawer, setEmployeeDetailsDrawer] = useState({ open: false, employee: null });

    const businessContext = useContext(BusinessContext);
    const businessId = businessContext.business.id;

    const { employees, loading, pagination, setPagination, setFilters, refetch } = useEmployees(businessId);
    const { confirm } = Modal;

    const onEdit = employee => setEmployeeDetailsDrawer({ open: true, employee });
    const onDelete = employee => confirm(employeeDeleteModalConfig(employee, businessId, refetch))

    return (
        <React.Fragment>
            <TableViewPage
                title='Employees'
                description='Add and manage everything about your employees.'
                search={{
                    loading: loading,
                    placeholder: 'Search by name or email',
                    handler: search => setFilters(prev => ({...prev, search: search}))
                }}
                add={{
                    text: 'Add Employee',
                    handler: () => setAddEmployeeModal(true)
                }}
                table={{
                    columns: employeeTableConfig(onEdit, onDelete),
                    data: employees,
                    loading: loading
                }}
                pagination={pagination}
                setPagination={setPagination}
                business={businessContext.business}
            />
            {employeeDetailsDrawer.open && (
                <ManageEmployee
                    open={employeeDetailsDrawer.open}
                    closeHandler={() => setEmployeeDetailsDrawer({ open: false, employee: null })}
                    businessId={businessId}
                    currency={businessContext.business.currency}
                    timezone={businessContext.business.timezone}
                    refetch={refetch}
                    employee={employeeDetailsDrawer.employee}
                />
            )}
            {addEmployeeModal && (
                <AddEmployee
                    open={addEmployeeModal}
                    closeHandler={() => setAddEmployeeModal(false)}
                    businessId={businessId}
                    refetch={refetch}
                />
            )}
        </React.Fragment>
    );
};

export default EmployeePage;