import React from 'react';
import { Space, Typography } from 'antd';

const ContactsEntry = ({icon, value}) => value && (
    <Space size='large' className='business-page-contacts__entry'>
        {React.cloneElement(icon, { size: 15 })}
        <Typography>{value}</Typography>
    </Space>
)

export default ContactsEntry;