import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import axios from 'axios';

import { passwordValidator, requiredNoMessage } from '../helpers/form-validators';

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { email, token } = useParams();

    const submitHandler = async values => {
        setLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/Identity/reset-password`, {
                    email: email,
                    token: decodeURIComponent(token),
                    newPassword: values.password
                });
    
            if (response.status == 200) {
                message.success('Your password has been successfully reset');
                navigate('/auth');
            }
        }
        catch(error) {
            if (error.response?.status === 400)
                message.error('Your password reset token has expired. Please request a new one')
        }

        setLoading(false);
    }

    return (
        <Row justify='center' align='middle' className='auth-wrapper'>
            <Col xs={20} sm={14} md={10} lg={8} xl={6} className='auth-box'>
                <Typography.Title className='auth-box__title' level={3}>Reset password</Typography.Title>
                <Form name='login' layout='vertical' requiredMark={false} onFinish={submitHandler}>
                    <Form.Item
                        label='New password'
                        name='password'
                        rules={[{...requiredNoMessage}, { validator: passwordValidator }]}
                        validateTrigger='submit'
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item className='form-item-margin-10'>
                        <Button loading={loading} type='primary' htmlType='submit' block>Reset</Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}

export default ResetPassword;