import { useEffect, useState } from 'react';
import axios from 'axios';

const useBusinessRoles = businessId => {
    const [roles, setRoles] = useState();

    useEffect(() => {
        if (!businessId)
            return;

        axios.get(`${process.env.REACT_APP_API}/business/${businessId}/roles`)
            .then(response => setRoles(response.data));
    }, [businessId]);

    return roles;
};

export default useBusinessRoles;