import React, { useContext, useState } from 'react';
import { Rate, Space, Typography, Divider, Button } from 'antd';
import { StarFilled } from '@ant-design/icons';
import CreateReviewModal from './CreateReviewModal';
import BusinessContext from '../../contexts/BusinessContext';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const ReviewsList = ({reviews}) => {
    const [reviewDialog, setReviewDialog] = useState(false);
    const business = useContext(BusinessContext);
    const isMobile = useCheckMobileScreen();

    return (
        <React.Fragment>
            <Button
                icon={<StarFilled />}
                className={`reviews-intro-button ${isMobile ? 'mobile' : ''}`}
                style={{ color: business.brandColor }}
                onClick={() => setReviewDialog(true)}>
                    Share your feedback
            </Button>
            {reviews?.length ? reviews.map(review => (
                <div className='reviews-wrapper'>
                    <div className='review-header'>
                        <Typography>{review.customerName}</Typography>
                        <Rate className='review-header__rate' disabled defaultValue={review.rate} />
                    </div>
                    <Typography.Text type='secondary'>{review.text}</Typography.Text>
                    <Divider style={{ margin: '10px 0px' }} />
                </div>
            )) : <Typography>We haven't received any feedback yet</Typography>}
            {reviewDialog && <CreateReviewModal open={reviewDialog} closeHandler={() => setReviewDialog(false)} />}
        </React.Fragment>
    );
}

export default ReviewsList;