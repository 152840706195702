import React from "react";

import { Col, Form, Input, Row, Space, Switch, Typography } from "antd";
import { MoonOutlined } from '@ant-design/icons';

import { formatTime } from "../../../helpers/parsers";
import { capitalize } from "../../../helpers/common";

const EditWorkingHours = ({form, workingHours}) => {
    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    const initialValues = daysOfWeek.reduce((acc, day) => {
        const hours = workingHours.find(x => x.dayOfWeek === capitalize(day));
        acc[`${day}Start`] = hours?.start || '';
        acc[`${day}End`] = hours?.end || '';
        acc[`${day}Switch`] = hours?.start || hours?.end ? true : false
        return acc;
    }, {});

    const handleTimeChange = (value, fieldName) => form.setFieldsValue({ [fieldName]: formatTime(value) });

    const timeValidator = (_, value) => {
        const [hours, minutes] = value.split(':').map(Number);
        return (hours >= 0 && hours <= 23) && (minutes >= 0 && minutes <= 59) ? Promise.resolve() : Promise.reject();
    };

    return (
        <React.Fragment>
            <Typography.Text type="secondary" style={{marginBottom: "20px", display: "block"}}>
                Configure employee working hours by day of the week.
            </Typography.Text>
            <Form form={form} layout="vertical" initialValues={initialValues}>
                {daysOfWeek.map(dayOfWeek => {
                    const startField = `${dayOfWeek}Start`;
                    const endField = `${dayOfWeek}End`;
                    const switchField = `${dayOfWeek}Switch`;

                    return (
                        <Row key={dayOfWeek} style={{ alignItems: "center", margin: "10px 0px" }}>
                            <Col span={10}>
                                <Space>
                                    <Form.Item noStyle name={switchField}>
                                        <Switch />
                                    </Form.Item>
                                    <Typography>{capitalize(dayOfWeek)}</Typography>
                                </Space>
                            </Col>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues[switchField] !== currentValues[switchField]}
                            >
                                {({ getFieldValue }) =>
                                    getFieldValue(switchField) ? (
                                        <Col span={14}>
                                            <Space>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    name={startField}
                                                    rules={[{ required: true, message: "", validator: timeValidator }]}
                                                >
                                                    <Input
                                                        addonBefore="Start"
                                                        placeholder="08:00"
                                                        onChange={event => handleTimeChange(event.target.value, startField)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    name={endField}
                                                    rules={[{ required: true, message: "", validator: timeValidator }]}
                                                >
                                                    <Input
                                                        addonBefore="End"
                                                        placeholder="17:00"
                                                        onChange={event => handleTimeChange(event.target.value, endField)}
                                                    />
                                                </Form.Item>
                                            </Space>
                                        </Col>
                                    ) : (
                                        <Col
                                            style={{
                                                background: "#fafafa",
                                                outline: "1px solid #f5f5f5",
                                                borderRadius: "5px",
                                                padding: "5px 10px"
                                            }}
                                            span={14}
                                        >
                                            <Space size="middle">
                                                <MoonOutlined style={{ color: '#8c8c8c' }} />
                                                <Typography.Text type="secondary">Not working</Typography.Text>
                                            </Space>
                                        </Col>
                                        )
                                    }
                            </Form.Item>
                        </Row>
                    );
                })}
            </Form>
        </React.Fragment>
    );
};

export default EditWorkingHours;