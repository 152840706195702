import { Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from 'axios';

const customerDeleteModalConfig = (customer, businessId, refetch) => ({
    title: `Confirm Deletion`,
    content: (
        <Typography.Text>
            Are you sure you want to delete the customer <b>{customer.name}</b>?
        </Typography.Text>
    ),
    icon: <ExclamationCircleFilled />,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
        return axios.delete(`${process.env.REACT_APP_API}/business/${businessId}/customers/${customer.id}`).then(refetch);
    }
})

export default customerDeleteModalConfig;