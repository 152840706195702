import React from 'react';
import { Col, Divider, Form, Input, InputNumber, Row, Space, Switch, Tooltip, Typography, Upload, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { requiredNoMessage } from '../../../helpers/form-validators';
import { validateFileUpload } from '../../../helpers/common';

const EditDetails = ({service, currency, form}) => {
    const upload = Form.useWatch('upload', form);

    const initialValues = {
        name: service.name,
        duration: service.duration,
        price: service.price,
        priorBuffer: service.priorBuffer,
        visibility: service.visibility,
        upload: service.image ? [{ url: service.image }] : []
    };

    return (
        <React.Fragment>
            <Form layout='vertical' form={form} initialValues={initialValues} requiredMark={false}>
                <Row>
                    <Col span={8} style={{display: 'flex', width: "100%", alignItems: 'center'}}>
                        <Form.Item
                            noStyle
                            name="upload"
                            valuePropName="fileList"
                            getValueFromEvent={(e) => e.fileList}
                        >
                            <Upload
                                beforeUpload={file => {
                                    if (validateFileUpload(file, ['image/jpeg', 'image/png'], 1024))
                                        return true;

                                    message.error('Please upload a JPEG or PNG file smaller than 1 MB');
                                    return Upload.LIST_IGNORE;
                                }}
                                listType="picture-circle"
                                className="avatar-uploader"
                                maxCount={1}
                                customRequest={({ onSuccess }) => onSuccess()}
                            >
                                {!upload?.length ? 'Add image' : null}
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item className="form-item-margin-10" label="Name" name="name" rules={[{ required: true }]}>
                            <Input placeholder="eg. Albert"/>
                        </Form.Item>
                        <Form.Item label='Price' name='price' rules={[{...requiredNoMessage}]} className='form-item-margin-10'>
                            <InputNumber
                                style={{width: '100%'}}
                                addonAfter={currency}
                                min={0}
                                placeholder='Enter price'
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item label='Duration' name='duration' rules={[{...requiredNoMessage}]}>
                            <InputNumber min={5} placeholder='Enter duration' addonAfter='mins' style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <Space>
                                    Prior Buffer
                                    <Tooltip title='Time to prep in between consecutive appointments'>
                                        <InfoCircleOutlined  />
                                    </Tooltip>
                                </Space>
                            }
                            name='priorBuffer'
                            className='form-item-margin-10'
                        >
                            <InputNumber
                                min={5}
                                placeholder='Enter buffer time'
                                addonAfter='mins'
                                style={{width: '100%'}}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={20}>
                    <Col span={24}>
                        <Space>
                            <Form.Item name='visibility' noStyle>
                                <Switch />
                            </Form.Item>
                            <Typography.Text>Visible in the booking page</Typography.Text>
                            <Tooltip title="Customers can't book this service unless it's checked, but employees can from their calendar">
                                <InfoCircleOutlined  />
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};

export default EditDetails;