import axios from "axios";

const saveContacts = async (employeeId, businessId, contactsForm) => {
    try {
        const fieldsValues = await contactsForm.validateFields();

        const contacts = {
            facebook: fieldsValues["facebook"],
            instagram: fieldsValues["instagram"],
            alternateEmail: fieldsValues["alternateEmail"],
            linkedIn: fieldsValues["linkedIn"],
            phone: fieldsValues["phone"],
            twitter: fieldsValues["twitter"],
            youTube: fieldsValues["youTube"],
            website: fieldsValues["website"]
        };

        const formData = new FormData();

        if (fieldsValues.upload.length) {
            formData.append('hasImage', true);

            if (fieldsValues.upload[0].originFileObj)
                formData.append('image', fieldsValues.upload[0].originFileObj);
        } else {
            formData.append('hasImage', false);
        }

        formData.append('name', fieldsValues['name']);
        formData.append('contacts', JSON.stringify(contacts));

        await axios.post(`${process.env.REACT_APP_API}/business/${businessId}/employees/${employeeId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return true;
    }
    catch(error) {
        return false;
    }
}

export default saveContacts;