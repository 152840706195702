import saveAttachedServices from "./save-attached-services";
import saveBreaks from "./save-breaks";
import saveContacts from "./save-contacts";
import saveTimeOffs from "./save-time-offs";
import saveWorkingHours from "./save-working-hours";

const saveTabsFactory = (employeeId, businessId, timezone, forms) => ({
    1: async () => await saveContacts(employeeId, businessId, forms.contactsForm),
    2: async () => await saveWorkingHours(employeeId, businessId, forms.workingHoursForm),
    3: async () => await saveBreaks(employeeId, businessId, forms.breaksForm),
    4: async () => await saveTimeOffs(employeeId, businessId, timezone, forms.timeOffsForm),
    5: async () => await saveAttachedServices(employeeId, businessId, forms.attachedServicesForm)
});

export default saveTabsFactory;