import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const useEmployeeDetails = (businessId, employee) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const fetchDetails = useCallback(async () => {
        if (!businessId)
            return;

        setLoading(true);
        var response = await axios.get(`${process.env.REACT_APP_API}/business/${businessId}/employees/${employee}`);

        setData({
            contacts: response.data.contacts,
            workingHours: response.data.workingHours,
            breaks: response.data.breaks,
            services: response.data.services,
            timeOffs: response.data.leaves
        });

        setLoading(false);
    }, [businessId, employee]);

    useEffect(() => {
        if (employee != null)
            fetchDetails();
    }, [employee, fetchDetails]);

    return { data, loading };
};

export default useEmployeeDetails;