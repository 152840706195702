import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

const useBusinessSlugData = businessSlug => {
    const [businessData, setBusinessData] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/business/slug/${businessSlug}`)
            .then(response => response.status === 200 ? setBusinessData(response.data) : navigate("/account"))
            .catch();
    }, [businessSlug]);

    return businessData;
}

export default useBusinessSlugData;