import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useEmployee = (employeeId, businessId) => {
    const [employee, setEmployee] = useState();
    const [loading, setLoading] = useState(false);

    const fetchEmployee = useCallback(async () => {
        setLoading(true);

        const response = await axios.get(
            `${process.env.REACT_APP_API}/business/${businessId}/employees/${employeeId}/simple`);

        setEmployee(response.data);
        setLoading(false);
    }, [businessId, employeeId]);

    useEffect(() => {
        if (businessId)
            fetchEmployee();
    }, [businessId, employeeId, fetchEmployee]);

    return { employee, loading };
};

export default useEmployee;