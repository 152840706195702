import React from 'react';

import { Col, Form, Input, Row, Space, Switch, Typography } from 'antd';
import { MoonOutlined } from '@ant-design/icons';

import { formatTime } from '../helpers/parsers';
import { capitalize } from '../helpers/common';
import { daysOfWeek } from '../helpers/data';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const WorkingHours = ({workingHours, form, submitHandler}) => {
    const isMobile = useCheckMobileScreen();

    const initialValues = daysOfWeek.reduce((acc, day) => {
        const hours = workingHours.find(x => x.dayOfWeek === capitalize(day));
        acc[`${day}Start`] = hours?.start || '';
        acc[`${day}End`] = hours?.end || '';
        acc[`${day}Switch`] = hours?.start || hours?.end ? true : false
        return acc;
    }, {});

    const handleTimeChange = (value, fieldName) => form.setFieldsValue({ [fieldName]: formatTime(value) });

    const timeValidator = (_, value) => {
        const [hours, minutes] = value.split(':').map(Number);
        return (hours >= 0 && hours <= 23) && (minutes >= 0 && minutes <= 59) ? Promise.resolve() : Promise.reject();
    };

    const rule = { required: true, message: '', validator: timeValidator };
    const colSize = isMobile ? 24 : 12;

    return (
        <Form form={form} layout='vertical' initialValues={initialValues} onFinish={submitHandler}>
            {daysOfWeek.map(dayOfWeek => {
                const startField = `${dayOfWeek}Start`;
                const endField = `${dayOfWeek}End`;
                const switchField = `${dayOfWeek}Switch`;

                return (
                    <Row key={dayOfWeek} className='working-hours-weekday-row'>
                        <Col className={`working-hours-weekday-row-title ${isMobile ? 'mobile' : ''}`} span={colSize}>
                            <Space>
                                <Form.Item noStyle name={switchField}>
                                    <Switch />
                                </Form.Item>
                                <Typography>{capitalize(dayOfWeek)}</Typography>
                            </Space>
                        </Col>
                        <Form.Item noStyle shouldUpdate={(prev, curr) => prev[switchField] !== curr[switchField]}>
                            {({ getFieldValue }) =>
                                getFieldValue(switchField) ? (
                                    <Col span={colSize}>
                                        <Space className='working-hours-weekday-row__space'>
                                            <Form.Item style={{ margin: 0 }} name={startField} rules={[rule]}>
                                                <Input
                                                    addonBefore='Opens'
                                                    placeholder='08:00'
                                                    onChange={event => handleTimeChange(event.target.value, startField)}
                                                />
                                            </Form.Item>
                                            <Form.Item style={{ margin: 0 }} name={endField} rules={[rule]}>
                                                <Input
                                                    addonBefore='Closes'
                                                    placeholder='17:00'
                                                    onChange={event => handleTimeChange(event.target.value, endField)}
                                                />
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                ) : (
                                    <Col
                                        className='working-hours-weekday-row-closed'
                                        span={colSize}
                                    >
                                        <Space size='middle'>
                                            <MoonOutlined className='working-hours-weekday-row-closed__icon' />
                                            <Typography.Text type='secondary'>Closed</Typography.Text>
                                        </Space>
                                    </Col>
                                    )
                                }
                        </Form.Item>
                    </Row>
                );
            })}
        </Form>
    );
};

export default WorkingHours;