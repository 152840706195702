import { Space, Typography } from 'antd';
import { BsFillStarFill } from 'react-icons/bs';

const ReviewsSummary = ({reviewSummary}) => (
    <Space className='booking-page-reviews'>
        <Typography.Text>{reviewSummary.rate}</Typography.Text>
        <BsFillStarFill />
        <Typography>({reviewSummary.reviewers} Reviewers)</Typography>
    </Space>
)

export default ReviewsSummary;