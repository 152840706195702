import { Space, Tag } from "antd";

import ServiceActions from "../components/table/ServiceActions";
import AttachedEmployees from "../components/table/AttachedEmployees";
import ServiceInfo from "../components/table/ServiceInfo";

const serviceTableConfig = (onEdit, onDelete, currency) => [
    {
        title: 'Information',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => <ServiceInfo service={record} />
    },
    {
        title: <Space>Price<Tag>{currency}</Tag></Space>,
        dataIndex: 'price',
        key: 'price'
    },
    {
        title: <Space>Duration<Tag>minutes</Tag></Space>,
        dataIndex: 'duration',
        key: 'duration'
    },
    {
        title: "Employees",
        dataIndex: "attachedEmployees",
        key: "attachedEmployees",
        render: (_, record) => <AttachedEmployees employees={record.attachedEmployees} />
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => <ServiceActions onEdit={() => onEdit(record)} onDelete={() => onDelete(record)} />,
        align: 'right',
    }
];

export default serviceTableConfig;