import EmployeeActions from "../components/table/EmployeeActions";
import EmployeeInfo from "../components/table/EmployeeInfo";

const employeeTableConfig = (onEdit, onDelete) => [
    {
        title: 'Information',
        dataIndex: 'email',
        key: 'email',
        render: (_, record) => <EmployeeInfo employee={record} />
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role'
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => <EmployeeActions onEdit={() => onEdit(record)} onDelete={() => onDelete(record)} />,
        align: 'right',
    }
];

export default employeeTableConfig;