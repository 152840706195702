import { forwardRef, useImperativeHandle } from 'react';
import { Col, Input, Row, Form, message } from 'antd';
import axios from 'axios';

const ContactsTab = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({ submit: () => form.submit() }));

    const submitHandler = async values => {
        props.setSaveLoading(true);

        try {
            await axios.put(`${process.env.REACT_APP_API}/Business/${props.businessId}/contacts`, {
                email: values.email,
                primaryPhone: values.primaryPhone,
                secondaryPhone: values.secondaryPhone,
                instagram: values.instagram,
                facebook: values.facebook,
                youtube: values.youtube,
                website: values.website
            });

            message.success('Contacts updated successfully');
        }
        catch(error) {}

        props.setSaveLoading(false);
    }

    return (
        <Form
            initialValues={{
                email: props.data.contacts?.email,
                primaryPhone: props.data.contacts?.primaryPhone,
                secondaryPhone: props.data.contacts?.secondaryPhone,
                instagram: props.data.contacts?.instagram,
                facebook: props.data.contacts?.facebook,
                youtube: props.data.contacts?.youtube,
                website: props.data.contacts?.website
            }}
            form={form} 
            onFinish={submitHandler}
            layout='vertical'
            requiredMark={false}
        >
            <Row gutter={10}>
                <Col span={props.colSize}>
                    <Form.Item label='Email' name='email'>
                        <Input placeholder='Enter an email' />
                    </Form.Item>
                </Col>
                <Col span={props.colSize}>
                    <Form.Item label='Primary phone' name='primaryPhone'>
                        <Input placeholder='Enter a primary phone' />
                    </Form.Item>
                </Col>
                <Col span={props.colSize}>
                    <Form.Item label='Secondary phone' name='secondaryPhone'>
                        <Input placeholder='Enter a secondary phone' />
                    </Form.Item>
                </Col>
                <Col span={props.colSize}>
                    <Form.Item label='Instagram' name='instagram'>
                        <Input placeholder='Enter profile url' />
                    </Form.Item>
                </Col>
                <Col span={props.colSize}>
                    <Form.Item label='Facebook' name='facebook'>
                        <Input placeholder='Enter profile url' />
                    </Form.Item>
                </Col>
                <Col span={props.colSize}>
                    <Form.Item label='YouTube' name='youtube'>
                        <Input placeholder='Enter profile url' />
                    </Form.Item>
                </Col>
                <Col span={props.colSize}>
                    <Form.Item label='Website' name='website'>
                        <Input placeholder='Enter website url' />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
});

export default ContactsTab;