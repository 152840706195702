import { useEffect, useState } from 'react';
import axios from 'axios';

const useCurrencies = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const fetch = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/External/currencies`);
            setData(response.data)
        }
        catch(error) {}

        setLoading(false);
    };

    useEffect(() => { fetch() }, []);
    return { data, loading, refetch: fetch };
}

export default useCurrencies;