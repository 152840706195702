import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import EmployeePage from './pages/EmployeePage';
import ServicePage from './pages/ServicePage';
import CustomerPage from './pages/CustomerPage';
import SettingsPage from './pages/SettingsPage';
import BookingPage from './pages/BookingPage/BookingPage';
import SlotsPage from './pages/BookingPage/SlotsPage';
import EmployeeBookingPage from './pages/BookingPage/EmployeeBookingPage';
import ServiceBookingPage from './pages/BookingPage/ServiceBookingPage';
import Calendar from './pages/Calendar';
import Authenticate from './pages/Authenticate';
import Account from './pages/Account';
import ResetPassword from './pages/ResetPassword';
import AuthenticatedRoute from './features/auth/components/AuthenticatedRoute';
import Business from './Business';
import App from './App';

import './styles.css';
import './translations/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route index element={<AuthenticatedRoute page={<Account />} />} />
                    <Route path="account" element={<AuthenticatedRoute page={<Account />} />} />
                    <Route path="auth" element={<Authenticate />} />
                    <Route path="reset-password/:email/:token" element={<ResetPassword />} />
                    <Route path="manage/:businessSlug" element={<AuthenticatedRoute page={<Business />} />}>
                        <Route path="calendar" element={<Calendar />} />
                        <Route path="employees" element={<EmployeePage />} />
                        <Route path="services" element={<ServicePage />} />
                        <Route path="customers" element={<CustomerPage />} />
                        <Route path="settings" element={<SettingsPage />} />
                    </Route>
                    <Route path=":businessSlug" element={<BookingPage />}>
                        <Route path="team/:employeeId" element={<EmployeeBookingPage />} />
                        <Route path="service/:serviceId" element={<ServiceBookingPage />} />
                        <Route path="slots/:employeeId/:serviceId" element={<SlotsPage />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);