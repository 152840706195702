import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const useEmployeeServiceListing = (businessId, employee) => {
    const [services, setServices] = useState();
    const [loading, setLoading] = useState(false);

    const fetchDetails = useCallback(async () => {
        if (!businessId)
            return;

        setLoading(true);
        
        var response = await axios.get(
            `${process.env.REACT_APP_API}/business/${businessId}/employees/${employee}/service-listing`);

        setServices(response.data);
        setLoading(false);
    }, [businessId, employee]);

    useEffect(() => {
        if (employee != null)
            fetchDetails();
    }, [employee, fetchDetails]);

    return { services, loading };
};

export default useEmployeeServiceListing;