import React from "react";

import { Button, Col, Form, Input, Row, Space, Typography } from "antd";
import { DeleteOutlined, PauseCircleOutlined } from '@ant-design/icons';

import { formatTime } from "../../../helpers/parsers";
import { timeValidator } from "../../../helpers/form-validators";

const EditBreaks = ({form, breaks}) => {
    const handleTimeChange = (value, fieldPath) => form.setFields([{name: fieldPath, value: formatTime(value)}]);
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const initialValues = daysOfWeek.map(dayOfWeek => ({
        dayOfWeek: dayOfWeek,
        intervals: breaks.find(x => x.dayOfWeek === dayOfWeek)?.intervals
    }));

    return (
        <React.Fragment>
            <Typography.Text type="secondary" style={{marginBottom: "20px", display: "block"}}>
                Configure employee breaks by day of the week, such as lunch, coffee, etc.
            </Typography.Text>
            <Form form={form} initialValues={initialValues}>
                {initialValues.map((day, index) => (
                    <Row key={day.dayOfWeek} style={{ margin: "10px 0px" }}>
                        <Form.Item name={[index, 'dayOfWeek']} noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.List name={[index, 'intervals']}>
                            {(fields, { add, remove }) => (
                                <React.Fragment>
                                    <Col span={10}>
                                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                            <Typography>{day.dayOfWeek}</Typography>
                                            <Form.Item style={{ margin: 0, marginRight: "10px" }}>
                                                <Button onClick={() => add()} block>
                                                    Add
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={14} style={{alignContent: "center"}}>
                                        {fields.length ? fields.map(({ key, name }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: "10px" }}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    name={[name, 'start']}
                                                    rules={[{ required: true, message: "", validator: timeValidator }]}
                                                >
                                                    <Input
                                                        addonBefore="Start"
                                                        placeholder="12:00"
                                                        onChange={event => handleTimeChange(event.target.value, [index, 'intervals', name, 'start'])}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    name={[name, 'end']}
                                                    rules={[{ required: true, message: "", validator: timeValidator }]}
                                                >
                                                    <Input
                                                        addonBefore="End"
                                                        placeholder="13:00"
                                                        onChange={event => handleTimeChange(event.target.value, [index, 'intervals', name, 'end'])}
                                                    />
                                                </Form.Item>
                                                <DeleteOutlined onClick={() => remove(name)} />
                                            </Space>
                                        )) : (
                                            <div
                                                style={{
                                                    background: "#fafafa",
                                                    outline: "1px solid #f5f5f5",
                                                    borderRadius: "5px",
                                                    padding: "5px 10px"
                                                }}
                                                span={14}
                                            >
                                                <Space size="middle">
                                                    <PauseCircleOutlined style={{ color: '#8c8c8c' }} />
                                                    <Typography.Text type="secondary">No breaks</Typography.Text>
                                                </Space>
                                            </div>
                                        )}
                                    </Col>
                                </React.Fragment>
                            )}
                        </Form.List>
                    </Row>
                ))}
            </Form>
        </React.Fragment>
    );
};

export default EditBreaks;