import { Typography } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';

import axios from "axios";

const serviceDeleteModalConfig = (service, businessId, refetch) => ({
    title: `Confirm Deletion`,
    content: (
        <Typography.Text>
            Are you sure you want to delete the service <b>{service.name}</b> which is currently assigned to {service.attachedEmployees.length} employees?
        </Typography.Text>
    ),
    icon: <ExclamationCircleFilled />,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
        return axios.delete(`${process.env.REACT_APP_API}/business/${businessId}/services/${service.id}`).then(refetch)
    }
})

export default serviceDeleteModalConfig;