import CustomerActions from '../components/table/CustomerActions';
import CustomerInfo from '../components/table/CustomerInfo';

const customerTableConfig = (onEdit, onDelete) => [
    {
        title: 'Information',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => <CustomerInfo customer={record} />
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => <CustomerActions onEdit={() => onEdit(record)} onDelete={() => onDelete(record)} />,
        align: 'right',
    }
];

export default customerTableConfig;