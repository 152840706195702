import React from 'react';
import { Avatar, Space, Typography } from 'antd';

const EmployeeInfo = ({ employee }) => (
    <Space size="middle">
        <Avatar size="large" src={employee.image}>{employee.name.slice(0, 2).toUpperCase()}</Avatar>
        <div>
            <Typography.Text>{employee.name}</Typography.Text><br />
            <Typography.Text type='secondary'>{employee.email}</Typography.Text>
        </div>
    </Space>
);

export default EmployeeInfo;