import React, { forwardRef, useImperativeHandle } from 'react';
import { Form, message, Typography } from 'antd';
import WorkingHours from '../../../components/WorkingHours';
import { getActiveWorkingHours } from '../../../helpers/data';
import axios from 'axios';

const BusinessHoursTab = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({ submit: () => form.submit() }));

    const submitHandler = async values => {
        props.setSaveLoading(true);

        try {
            const workingHours = getActiveWorkingHours(values);
            await axios.put(`${process.env.REACT_APP_API}/business/${props.businessId}/business-hours`, { workingHours });

            message.success('Business hours updated successfully');
        }
        catch(error) {}

        props.setSaveLoading(false);
    }

    return (
        <React.Fragment>
            <Typography.Text type='secondary' className='tip-text'>
                Set your business hours to let clients know when you're available for bookings
            </Typography.Text>
            <WorkingHours workingHours={props.data.businessHours} form={form} submitHandler={submitHandler} />
        </React.Fragment>
    );
});

export default BusinessHoursTab;