import React from 'react';
import { Button } from 'antd';
import { redirect } from '../../helpers/common';

const SocialMediaButton = ({icon, link}) => link && (
    <Button
        type='text'
        shape='circle'
        icon={React.cloneElement(icon, { size: 20 })}
        onClick={() => redirect(link, true)}
    />
)

export default SocialMediaButton;