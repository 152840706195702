import React, { useContext, useState } from 'react';
import { Modal } from 'antd';

import serviceDeleteModalConfig from '../features/services/configs/service-delete-modal';
import serviceTableConfig from '../features/services/configs/service-table';
import useServices from '../features/services/hooks/useServices';
import AddService from '../features/services/components/AddService';
import ManageService from '../features/services/components/ManageService';

import TableViewPage from '../components/TableViewPage';
import BusinessContext from '../contexts/BusinessContext';

const ServicePage = () => {
    const [addServiceModal, setAddServiceModal] = useState(false);
    const [serviceDetailsDrawer, setServiceDetailsDrawer] = useState({ open: false, service: null });

    const businessContext = useContext(BusinessContext);
    const businessId = businessContext.business.id;

    const { services, loading, pagination, setPagination, setFilters, refetch } = useServices(businessId);
    const { confirm } = Modal;

    const onEdit = service => setServiceDetailsDrawer({ open: true, service });
    const onDelete = service => confirm(serviceDeleteModalConfig(service, businessId, refetch))

    return (
        <React.Fragment>
            <TableViewPage
                title='Services'
                description='Add and manage everything about your services.'
                search={{
                    loading: loading,
                    placeholder: 'Search by name',
                    handler: search => setFilters(prev => ({...prev, search: search}))
                }}
                add={{
                    text: 'Add Service',
                    handler: () => setAddServiceModal(true)
                }}
                table={{
                    columns: serviceTableConfig(onEdit, onDelete, businessContext.business.currency),
                    data: services,
                    loading: loading
                }}
                pagination={pagination}
                setPagination={setPagination}
                business={businessContext.business}
            />
            {serviceDetailsDrawer.open && (
                <ManageService
                    open={serviceDetailsDrawer.open}
                    closeHandler={() => setServiceDetailsDrawer({ open: false, service: null })}
                    businessId={businessId}
                    currency={businessContext.business.currency}
                    refetch={refetch}
                    service={serviceDetailsDrawer.service}
                />
            )}
            {addServiceModal && (
                <AddService
                    open={addServiceModal}
                    closeHandler={() => setAddServiceModal(false)}
                    businessId={businessId}
                    currency={businessContext.business.currency}
                    refetch={refetch}
                />
            )}
        </React.Fragment>
    );
};

export default ServicePage;