import { useContext } from 'react';
import { Layout, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import UserContext from '../contexts/UserContext';
import BusinessProfiles from '../features/account/BusinessProfiles';
import Header from '../components/Header';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const Account = () => {
    const userContext = useContext(UserContext);
    const isMobile = useCheckMobileScreen();

    const tabs = [
        { key: 1, label: 'Business Profiles', children: <BusinessProfiles /> },
        { key: 2, label: 'Reservations' },
        { key: 3, label: 'Favourites' }
    ];

    return (
        <Layout>
            <Header username={userContext.user.email} />
            <Layout className='layout-business'>
                <Content className='layout-business__content'>
                    <div className={`page ${isMobile ? 'mobile' : ''}`}>
                        <Tabs items={tabs} />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default Account;