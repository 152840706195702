import React, { forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Upload, message, Modal } from 'antd';
import axios from 'axios';
import { validateFileUpload } from '../../../../helpers/common';
import BookingPageTab from './BookingPageTab';

const BookingPageTabContainer = forwardRef((props, ref) => {
    const { confirm } = Modal;
    
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({ submit: () => form.submit() }))

    const appendFormDataFile = (name, hasIndicator, values, formData) => {
        if (values[name].length) {
            formData.append(hasIndicator, true);

            if (values[name][0].originFileObj)
                formData.append(name, values[name][0].originFileObj);
        } else {
            formData.append(hasIndicator, false);
        }
    }

    const submitHandler = async (values, slugChanged = false) => {
        props.setSaveLoading(true);
        const formData = new FormData();

        formData.append('slug', values.slug);
        appendFormDataFile('logo', 'hasLogo', values, formData);
        appendFormDataFile('cover', 'hasCover', values, formData);

        if (values.about)
            formData.append('about', values.about);

        if (values.brandColor) {
            if (typeof values.brandColor === 'object') {
                if (!values.brandColor.cleared)
                    formData.append('brandColor', values.brandColor.toHexString());
            }
            else formData.append('brandColor', values.brandColor);
        }

        try {
            const headers = { 'Content-Type': 'multipart/form-data' };

            try {
                await axios.put(`${process.env.REACT_APP_API}/Business/${props.businessId}/booking-page`, formData, { headers });
                message.success('Booking page updated successfully');

                if (slugChanged)
                    navigate(`/account`);
            }
            catch(error) {
                if (error.response.status === 409)
                    message.error('This slug is already in use by another business');
            }
        }
        catch(error) {}

        props.setSaveLoading(false);
    }

    const submitHandlerMiddleware = values => {
        if (values.slug === props.data.slug) {
            submitHandler(values);
        } else {
            confirm({
                title: `Confirm Slug Change`,
                content: `Are you sure you want to change the slug from ${props.data.slug} to ${values.slug}?`,
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'Cancel',
                onOk: () => submitHandler(values, true)
            });
        }
    }

    const uploadValidationHandler = file => {
        if (validateFileUpload(file, ['image/jpeg', 'image/png'], 2048))
            return true;

        message.error('Please upload a JPEG or PNG file smaller than 2 MB');
        return Upload.LIST_IGNORE;
    }

    return (
        <BookingPageTab
            colSize={props.colSize}
            data={props.data}
            form={form}
            submitHandler={submitHandlerMiddleware}
            uploadValidationHandler={uploadValidationHandler}
        />
    )
});

export default BookingPageTabContainer;