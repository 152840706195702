import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { changeLanguage } from 'i18next';

import { Button, Col, ConfigProvider, Image, Layout, Row, Skeleton, Tabs, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout';

import  { LeftOutlined } from '@ant-design/icons';

import TeamPage from './TeamPage';
import ServicesPage from './ServicesPage';

import useEmployees from '../../features/employees/hooks/useEmployees';
import useServices from '../../features/services/hooks/useServices';
import useBusinessSlugData from '../../hooks/useBusinessSlugData';
import useBusinessData from '../../hooks/useBusinessData';

import BusinessContext from '../../contexts/BusinessContext';

import { daysOfWeek } from '../../helpers/data';
import { capitalize } from '../../helpers/common';
import { defaultBrandColor } from '../../features/booking/config';
import SiderDesktop from '../../features/booking/SiderDesktop';
import HeaderMobile from '../../features/booking/HeaderMobile';
import CollapseMobile from '../../features/booking/CollapseMobile';
import ReviewsList from '../../features/booking/ReviewsList';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const BookingPage = () => {
    const { businessSlug, employeeId, serviceId } = useParams();
    const navigate = useNavigate();

    const business = useBusinessSlugData(businessSlug);
    const { businessData, loading, refetch } = useBusinessData(business?.id)

    const employees = useEmployees(business?.id);
    const services = useServices(business?.id);
    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        if (businessData)
            changeLanguage(businessData.translationCode)
    }, [businessData]);

    const brandColor = businessData?.brandColor ?? defaultBrandColor;

    const theme = {
        token: { colorPrimary: brandColor },
        components: { Button: { primaryShadow: 'none' } }
    };

    const businessHours = businessData?.businessHours ? daysOfWeek.reduce((acc, curr) => {
        const dayOfWeek = capitalize(curr);
        const start = businessData.businessHours.find(x => x.dayOfWeek === dayOfWeek)?.start;
        const end = businessData.businessHours.find(x => x.dayOfWeek === dayOfWeek)?.end;

        acc[dayOfWeek] = start && end ? `${start} - ${end}` : 'Closed';
        return acc;
    }, {}) : {};

    const tabs = [
        {
            key: 1,
            label: 'Team',
            children: <TeamPage employees={employees.employees.records} />
        },
        {
            key: 2,
            label: 'Services',
            children: <ServicesPage currency={business?.currency} services={services.services.records} />
        },
        {
            key: 3,
            label: 'About',
            children: <Typography className='booking-page-about-text'>{businessData?.about}</Typography>
        },
        {
            key: 4,
            label: 'Reviews',
            children: <ReviewsList reviews={businessData?.reviews} />
        }
    ]

    const mobile = isMobile ? 'mobile' : '';

    return business && (
        <ConfigProvider theme={theme}>
            <BusinessContext.Provider value={{...businessData, refetch}}>
                <Row justify='center' align='top' className={`booking-page-wrapper ${mobile}`}>
                    <Col className={`booking-page ${mobile}`}>
                        {loading ? <Skeleton className='booking-page__loading-skeleton' active /> : (
                            <React.Fragment>
                                {businessData?.coverImage && (
                                    <div className='booking-page-cover'>
                                        <Image
                                            className={`booking-page-cover__image ${mobile}`}
                                            preview={false}
                                            src={businessData.coverImage}
                                        />
                                    </div>
                                )}
                                <Layout className={`booking-page-layout ${mobile}`}>
                                    {!isMobile && (
                                        <SiderDesktop
                                            businessHours={businessHours}
                                            businessData={businessData}
                                            brandColor={brandColor}
                                        />
                                    )}
                                    <Content>
                                        {isMobile && (
                                            <React.Fragment>
                                                <HeaderMobile
                                                    businessData={businessData}
                                                    businessHours={businessHours}
                                                    brandColor={brandColor}
                                                />
                                                <CollapseMobile
                                                    businessData={businessData}
                                                    businessHours={businessHours}
                                                />
                                            </React.Fragment>
                                        )}
                                        {employeeId || serviceId ? (
                                            <div>
                                                <Button
                                                    type='default'
                                                    onClick={() => navigate(`/${businessSlug}`)}
                                                    className='booking-page-back-button'
                                                    icon={<LeftOutlined />}
                                                >
                                                    Back to home page
                                                </Button>
                                                <Outlet />
                                            </div>
                                        ) : <Tabs centered className='booking-page-tabs' items={tabs} />}
                                    </Content>
                                </Layout>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </BusinessContext.Provider>
        </ConfigProvider>
    )
}

export default BookingPage;