import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const useBusinessData = businessId => {
    const [businessData, setBusinessData] = useState();
    const [loading, setLoading] = useState(false);

    const fetchBusinessData = useCallback(async () => {
        setLoading(true);

        await axios.get(`${process.env.REACT_APP_API}/business/${businessId}`)
            .then(response => setBusinessData(response.data))
            .catch();

        setLoading(false);
    }, [businessId]);

    useEffect(() => {
        if (businessId)
            fetchBusinessData();
    }, [businessId]);

    return { businessData, loading, refetch: fetchBusinessData };
}

export default useBusinessData;