import axios from "axios";
import { capitalize } from "../../../helpers/common";

const saveWorkingHours = async (employeeId, businessId, form) => {
    try {
        const fieldsValues = await form.validateFields();
        const workingHours = [];
        const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        daysOfWeek.forEach(day => {
            if (fieldsValues[`${day}Switch`]) {
                workingHours.push({
                    dayOfWeek: capitalize(day),
                    start: fieldsValues[`${day}Start`],
                    end: fieldsValues[`${day}End`]
                });
            }
        });

        await axios.put(`${process.env.REACT_APP_API}/business/${businessId}/employees/${employeeId}/update-working-hours`, { workingHours });
        return true;
    }
    catch(error) {
        return false;
    }
}

export default saveWorkingHours;