import React from 'react';
import { Avatar, Space, Typography } from 'antd';

const CustomerInfo = ({ customer }) => (
    <Space size="middle">
        <Avatar size="large" src={customer.image}>{customer.name.slice(0, 2).toUpperCase()}</Avatar>
        <div>
            <Typography.Text>{customer.name}</Typography.Text><br />
            <Typography.Text type='secondary'>{customer.email}</Typography.Text>
        </div>
    </Space>
);

export default CustomerInfo;