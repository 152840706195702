import EditAttachedEmployees from "../components/EditAttachedEmployees";
import EditDetails from "../components/EditDetails";

const serviceEditTabsConfig = (service, currency, forms, data) => [
    {
      key: 1,
      label: 'Details',
      children: <EditDetails service={service} currency={currency} form={forms.detailsForm} />
    },
    {
      key: 2,
      label: 'Employees',
      children: <EditAttachedEmployees service={service} currency={currency} form={forms.attachedEmployeesForm} employees={data.employees} />
    }
];

export default serviceEditTabsConfig;