import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import ServicesPage from './ServicesPage';
import BusinessContext from '../../contexts/BusinessContext';
import useEmployeeServiceListing from '../../features/employees/hooks/useEmployeeServiceListing';
import useEmployee from '../../features/employees/hooks/useEmployee';

const EmployeeBookingPage = () => {
    const { businessSlug, employeeId } = useParams();
    const { t } = useTranslation();

    const business = useContext(BusinessContext);
    const listing = useEmployeeServiceListing(business?.id, employeeId);
    const { employee } = useEmployee(employeeId, business?.id);

    return employee && (
        <React.Fragment>
            <Typography className='booking-page-listing-title'>Services by {employee.name}</Typography>
            {listing.services?.length
                ? (
                    <ServicesPage
                        businessSlug={businessSlug}
                        employeeId={employeeId}
                        currency={business.currency}
                        services={listing.services}
                    />
                )
                : <Typography>No listings available</Typography>}
        </React.Fragment>
    )
}

export default EmployeeBookingPage;