import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useEmployees = (businessId, pageSize = 5) => {
    const [employees, setEmployees] = useState({ total: 0, records: [] });
    const [pagination, setPagination] = useState({ page: 1, size: pageSize });
    const [filters, setFilters] = useState({ search: null });
    const [loading, setLoading] = useState(false);

    const fetchEmployees = useCallback(async () => {
        setLoading(true);
        const params = { page: pagination.page, size: pagination.size };

        if (filters.search)
            params.search = filters.search;

        const response = await axios.get(`${process.env.REACT_APP_API}/business/${businessId}/employees`, {params});

        setEmployees(response.data);
        setLoading(false);
    }, [businessId, pagination, filters]);

    useEffect(() => {
        if (businessId)
            fetchEmployees();
    }, [businessId, pagination, fetchEmployees]);

    return { employees, loading, pagination, setPagination, setFilters, refetch: fetchEmployees };
};

export default useEmployees;