import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Card, Pagination, Skeleton, Typography } from 'antd';

import BookingModal from '../features/booking/components/calendar/BookingModal';
import BusinessContext from '../contexts/BusinessContext';
import useEmployees from '../features/employees/hooks/useEmployees';
import useEmployeeAppointments from '../features/booking/hooks/useEmployeeAppointments';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

import { Calendar as BigCalendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import dayjs from 'dayjs';
import 'dayjs/locale/bg';
import 'dayjs/locale/en';

dayjs.locale('en');

const Calendar = () => {
    const [events, setEvents] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [eventModal, setEventModal] = useState();
    
    const businessContext = useContext(BusinessContext);
    const businessId = businessContext.business.id;
    const isMobile = useCheckMobileScreen();

    const pageSize = isMobile ? 3 : 5;

    const { employees, loading, pagination, setPagination } = useEmployees(businessId, pageSize);
    const appointments = useEmployeeAppointments(selectedEmployee?.id, businessId);

    useEffect(() => {
        if (appointments.data)
            setEvents(appointments.data.map(x => ({
                appointmentId: x.appointmentId,
                title: x.label,
                start: new Date(x.start),
                end: new Date(x.end),
                serviceId: x.serviceId,
                customer: x.customer,
                price: x.price
            })));
    }, [appointments.data]);

    const formats = {
        timeGutterFormat: 'HH:mm',
        eventTimeRangeFormat: ({ start, end }) => `${dayjs(start).format('HH:mm')} - ${dayjs(end).format('HH:mm')}`,
        agendaTimeRangeFormat: ({ start, end }) => `${dayjs(start).format('HH:mm')} - ${dayjs(end).format('HH:mm')}`,
    };

    const CustomTimeSlot = ({ children, value, resource, onClick }) => {
        const [isHovered, setIsHovered] = useState(false);

        if (resource === undefined) {
            return children;
        }

        const event = events.find(event => dayjs(value).isBetween(dayjs(event.start), dayjs(event.end), null, '[)'));

        return event ? children : (
            <div 
                className="rbc-time-slot" 
                onClick={onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {isHovered && dayjs(value).format('HH:mm')}
            </div>
        );
    };

    const mobileClass = isMobile ? 'mobile' : '';
    const localizer = dayjsLocalizer(dayjs);

    return (
        <React.Fragment>
            <div className={`page ${isMobile ? 'mobile' : ''}`}>
                <div className='page__header'>
                    <Typography.Title level={3} className='page-title'>Calendar</Typography.Title>
                </div>
                <Typography.Text type="secondary">Effortlessly manage your bookings, for you or your team.</Typography.Text>
                <div className='employee-selection-wrapper'>
                    <Skeleton loading={loading} active>
                        <div className={`employee-selection-card ${mobileClass}`}>
                            {employees.records.map(employee => (
                                <Card
                                    onClick={() => setSelectedEmployee(employee)}
                                    className={`calendar-employee-card 
                                        ${mobileClass} 
                                        ${selectedEmployee?.id === employee.id ? 'active' : ''}`}
                                >
                                    <Avatar size="large" src={employee.image}>
                                        {employee.name.slice(0, 2).toUpperCase()}
                                    </Avatar>
                                    <Typography.Text>
                                        {employee.name.length > 10 ? `${employee.name.slice(0, 10)}...` : employee.name}
                                    </Typography.Text>
                                </Card>
                            ))}
                        </div>
                    </Skeleton>
                    <Pagination
                        hideOnSinglePage
                        size='small'
                        showSizeChanger={false}
                        className='table-pagination'
                        align={isMobile ? 'center' : 'left'}
                        total={employees.total}
                        current={pagination.page}
                        onChange={page => setPagination(prev => ({...prev, page: page}))}
                        pageSize={pagination.size}
                    />
                </div>
                {events && (
                    <Skeleton loading={appointments.loading} active>
                        <BigCalendar
                            localizer={localizer}
                            selectable
                            onSelectSlot={slot => setEventModal({ slot, employee: selectedEmployee })}
                            onSelectEvent={event => setEventModal({ edit: event, employee: selectedEmployee })}
                            events={events}
                            step={15}
                            timeslots={4}
                            style={{ minHeight: "100vh", marginTop: "40px" }}
                            formats={formats}
                            defaultView='day'
                            components={{ timeSlotWrapper: props => <CustomTimeSlot {...props} /> }}
                        />
                    </Skeleton>
                )}
            </div>
            {eventModal && (
                <BookingModal
                    open={Boolean(eventModal)}
                    closeHandler={() => setEventModal()}
                    slot={eventModal.slot}
                    business={businessContext.business}
                    employee={eventModal.employee}
                    refetch={appointments.refetch}
                    edit={eventModal.edit}
                />
            )}
        </React.Fragment>
    );
};

export default Calendar;