import React from 'react';
import { Col, Input, Row, Form, ColorPicker, Typography, QRCode, Upload, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";
import TextArea from 'antd/es/input/TextArea';
import { requiredNoMessage } from '../../../../helpers/form-validators';

const BookingPageTab = ({colSize, form, data, submitHandler, uploadValidationHandler}) => {
    const cover = Form.useWatch('cover', form);
    const logo = Form.useWatch('logo', form);
    const slug = Form.useWatch('slug', form);

    const uploadImageTooltip = label => (
        <Space>
            {label}
            <Tooltip title='JPEG/PNG, up to 2 MB'>
                <InfoCircleOutlined  />
            </Tooltip>
        </Space>
    );

    return (
        <React.Fragment>
            <Form
                initialValues={{
                    brandColor: data.brandColor,
                    slug: data.slug,
                    about: data.about,
                    logo: data.logo ? [{ url: data.logo }] : [],
                    cover: data.coverImage ? [{ url: data.coverImage }] : [],
                }}
                form={form}
                onFinish={submitHandler}
                layout='vertical'
                requiredMark={false}
            >
                <Row gutter={10}>
                    <Col span={colSize}>
                        <Form.Item
                            label={
                                <Space>
                                    Brand color
                                    <Tooltip title="Choose a brand color to enhance your visual identity">
                                        <InfoCircleOutlined  />
                                    </Tooltip>
                                </Space>
                            }
                            name='brandColor'>
                            <ColorPicker
                                allowClear
                                className='booking-page-settings-color-picker'
                                showText={() => (
                                    <Typography.Text type='secondary'>
                                        Clear selection for default color
                                    </Typography.Text>
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={colSize}>
                        <Form.Item label='Slug' name='slug' rules={[{...requiredNoMessage}]}>
                            <Input
                                addonBefore={`${window.location.origin}/`}
                                placeholder='Enter a unique slug, e.g., beauty-room'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={colSize}>
                        <Space size="large">
                            <Form.Item
                                label={uploadImageTooltip("Cover")}
                                name='cover'
                                valuePropName='fileList'
                                getValueFromEvent={(e) => e.fileList}
                            >
                                <Upload
                                    beforeUpload={uploadValidationHandler}
                                    listType='picture-card'
                                    className='avatar-uploader'
                                    maxCount={1}
                                    customRequest={({ onSuccess }) => onSuccess()}
                                >
                                    {!cover?.length ? 'Upload' : null}
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                label={uploadImageTooltip("Logo")}
                                name='logo'
                                valuePropName='fileList'
                                getValueFromEvent={(e) => e.fileList}
                            >
                                <Upload
                                    beforeUpload={uploadValidationHandler}
                                    listType='picture-card'
                                    className='avatar-uploader'
                                    maxCount={1}
                                    customRequest={({ onSuccess }) => onSuccess()}
                                >
                                    {!logo?.length ? 'Upload' : null}
                                </Upload>
                            </Form.Item>
                        </Space>
                    </Col>
                    <Col span={colSize}>
                        <Form.Item label='About your business' name='about'>
                            <TextArea placeholder='Describe your business' autoSize={{ minRows: 4, maxRows: 4 }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {slug && <div>
                <Typography.Text type='secondary'>
                    Your unique QR code. Make it easy for customers to reach your booking page. 
                </Typography.Text>
                <QRCode value={`${window.location.origin}/${slug}`} className='booking-page-settings-qr-code' />
            </div>}
        </React.Fragment>
    )
};

export default BookingPageTab;