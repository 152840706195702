import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Rate, Typography, Modal, Form, Input, Row, Col, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { requiredNoMessage } from '../../helpers/form-validators';
import BusinessContext from '../../contexts/BusinessContext';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const CreateReviewModal = ({open, closeHandler}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const business = useContext(BusinessContext);
    const isMobile = useCheckMobileScreen();

    const submitHandler = async values => {
        if (!values.rate) {
            message.warning('Oops! Don’t forget to choose a rating');
            return;
        }

        setLoading(true);

        try {
            await axios.post(`${process.env.REACT_APP_API}/business/${business.id}/review`, {
                name: values.name,
                phone: values.phone,
                email: values.email,
                rate: values.rate,
                text: values.text
            });

            message.success('Thanks! Your feedback helps us improve');
            business.refetch();
        }
        catch(error) {
            if (error.response?.status === 409) {
                if (error.response.data === 'ReviewRequiresAppointment')
                    message.warning('You can leave a review after completing an appointment');

                if (error.response.data === 'AlreadyGivenReview')
                    message.warning('Looks like you\'ve already left a review');
            }
        }

        setLoading(false);
    }

    const colSize = isMobile ? 24 : 12;

    return (
        <Modal
            title='Give us your feedback'
            okButtonProps={{loading}}
            open={open}
            okText='Leave a review'
            onOk={() => form.submit()} 
            onCancel={closeHandler}
        >
            <Typography.Text type='secondary'>How was your experience with us?</Typography.Text>
            <Form form={form} layout='vertical' onFinish={submitHandler}>
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item className='form-item-margin-10 create-review-rate-form-item' name='rate'>
                            <Rate className='create-review-rate' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item className='form-item-margin-10' name='name' rules={[{...requiredNoMessage}]}>
                            <Input placeholder='Your name' />
                        </Form.Item>
                    </Col>
                    <Col span={colSize}>
                        <Form.Item className='form-item-margin-10' name='phone' rules={[{...requiredNoMessage}]}>
                            <Input placeholder='Your phone' />
                        </Form.Item>
                    </Col>
                    <Col span={colSize}>
                        <Form.Item className='form-item-margin-10' name='email' rules={[{...requiredNoMessage}]}>
                            <Input placeholder='Your email' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item className='form-item-margin-10' name='text' rules={[{...requiredNoMessage}]}>
                            <TextArea
                                placeholder='Tell us more about your experience'
                                autoSize={{ minRows: 4, maxRows: 4 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default CreateReviewModal;