import React from 'react';
import { Avatar, Space, Typography } from 'antd';

const ServiceInfo = ({ service }) => (
    <Space size="middle">
        <Avatar size="large" src={service.image}>{service.name.slice(0, 2).toUpperCase()}</Avatar>
        <Typography.Text>{service.name}</Typography.Text><br />
    </Space>
);

export default ServiceInfo;