import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const useServiceDetails = (businessId, serviceId) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const fetchDetails = useCallback(async () => {
        setLoading(true);
        var response = await axios.get(`${process.env.REACT_APP_API}/business/${businessId}/services/${serviceId}`);

        setData({
            employees: response.data.employees
        });

        setLoading(false);
    }, [businessId, serviceId]);

    useEffect(() => {
        if (serviceId != null)
            fetchDetails();
    }, [serviceId, fetchDetails]);

    return { data, loading };
};

export default useServiceDetails;