import { Avatar, Space, Typography } from "antd";
import ReviewsSummary from "./ReviewsSummary";

const HeaderMobile = ({businessData, brandColor}) => (
    <Space className={`booking-page-mobile-summary ${!businessData?.coverImage ? 'no-cover' : ''}`}>
        <Avatar className='booking-page-mobile-summary__logo' src={businessData?.logo} />
        <div>
            <Typography className='booking-page-mobile-summary__title' style={{ color: brandColor }} >
                {businessData?.name}
            </Typography>
            {businessData?.reviewSummary && <ReviewsSummary reviewSummary={businessData.reviewSummary} />}
        </div>
    </Space>
)

export default HeaderMobile;