import { useNavigate } from 'react-router-dom';
import { Button, Space, Typography } from 'antd';
import { Header as AntdHeader } from 'antd/es/layout/layout'
import { BellOutlined, HomeOutlined } from '@ant-design/icons';
import UserDropdownMenu from './UserDropdownMenu';

const Header = ({username}) => {
    const navigate = useNavigate();

    return (
        <AntdHeader className='header'>
            <Typography className='header__title'>BookItOne</Typography>
            <Space>
                <UserDropdownMenu name={username} />
                <Button shape='circle' icon={<HomeOutlined />} onClick={() => navigate('/account')} />
                <Button shape='circle' icon={<BellOutlined />} />
            </Space>
        </AntdHeader>
    )
}

export default Header;