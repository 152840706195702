import { Col, Form, Input, InputNumber, message, Modal, Row, Select, Space, Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { useState } from "react";
import axios from "axios";

const AddService = ({open, closeHandler, businessId, currency, refetch}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const submitHandler = async values => {
        setLoading(true);

        try {
            await axios.post(`${process.env.REACT_APP_API}/Business/${businessId}/services`, {
                name: values.name, price: values.price, duration: values.duration
            });

            message.success("Service successfully created");
            refetch();
            closeHandler();
        }
        catch(error) {
            if (error.response.status === 409)
                message.error("A service with this name already exists");
        }

        setLoading(false);
    }

    return (
        <Modal title="Add service" okButtonProps={{loading}} open={open} okText="Add Service" onOk={() => form.submit()} onCancel={closeHandler}>
            <Typography.Text type="secondary">Enter essential service details now, assign employees later.</Typography.Text>
            <Form style={{marginTop: "20px"}} form={form} name="add-employee" layout="vertical" requiredMark={false} onFinish={submitHandler}>
                <Row>
                    <Col span={24}>
                        <Form.Item 
                            label="Name"
                            name="name"
                            rules={[
                                { required: true, message: '' }
                            ]}
                            className="form-item-margin-10"
                            validateTrigger="submit"
                        >
                                <Input placeholder="Enter service name" />
                        </Form.Item>
                    </Col>
 
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label="Duration"
                            name="duration"
                            rules={[{ required: true, message: '' }]}

                        >
                            <InputNumber min={5} placeholder="Enter duration" addonAfter="minutes" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Price"
                            name="price"
                            rules={[ { required: true, message: '' }]}
                        >
                            <InputNumber
                                addonAfter={currency}
                                min={0}
                                placeholder="Enter price" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default AddService;