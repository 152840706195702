import { Outlet } from 'react-router-dom';
import UserContext from './contexts/UserContext';

import useAuth from './features/auth/hooks/useAuth';
import useAxiosInterceptors from './hooks/useAxiosInterceptors';

const App = () => {
    const { user, setUser } = useAuth();
    useAxiosInterceptors(setUser);
    
    return (
        <UserContext.Provider value={{user, setUser}}>
            <Outlet />
        </UserContext.Provider>
    )
}

export default App;