import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Input, Row, Select, Form, message } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import { requiredNoMessage } from '../../../helpers/form-validators';

const GeneralTab = forwardRef((props, ref) => {
    const [currencies, setCurrencies] = useState([]);
    const [industries, setIndustries] = useState([]);

    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({ submit: () => form.submit() }));

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/External/currencies`).then(response => setCurrencies(response.data));
        axios.get(`${process.env.REACT_APP_API}/Business/industries`).then(response => setIndustries(response.data));
    }, []);

    const submitHandler = async values => {
        props.setSaveLoading(true);

        try {
            await axios.put(`${process.env.REACT_APP_API}/Business/${props.businessId}/general`, {
                industry: values.industry,
                name: values.name,
                currency: values.currency,
                timezone: values.timezone,
                address: values.address
            });

            message.success('General updated successfully');
        }
        catch(error) {}

        props.setSaveLoading(false);
    }

    return props.data && (
        <Form
            initialValues={{
                name: props.data.name,
                currency: props.data.currency,
                industry: props.data.industry,
                timezone: props.data.timezone,
                address: props.data.address
            }}
            form={form}
            onFinish={submitHandler}
            layout='vertical'
            requiredMark={false}
        >
            <Row gutter={10}>
                <Col span={props.colSize}>
                    <Form.Item  label='Name' name='name' rules={[{...requiredNoMessage}]}>
                        <Input placeholder='Enter your business name' />
                    </Form.Item>
                </Col>
                <Col span={props.colSize}>
                    <Form.Item label='Industry' name='industry' rules={[{...requiredNoMessage}]}>
                        <Select showSearch placeholder='Select your business industry'>
                            {industries.map(industry => (
                                <Select.Option key={industry.id} value={industry.id}>{industry.name}</Select.Option> 
                            ))}
                        </Select>
                    </Form.Item>
                </Col> 
                <Col span={props.colSize}>
                    <Form.Item label='Currency' name='currency' rules={[{...requiredNoMessage}]}>
                        <Select showSearch placeholder='Select your business currency'>
                            {currencies.map((currency, index) => (
                                <Select.Option key={index} value={currency.name}>
                                    {`${currency.name} (${currency.description})`}
                                </Select.Option> 
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={props.colSize}>
                    <Form.Item label='Timezone' name='timezone' rules={[{...requiredNoMessage}]}>
                        <Select showSearch placeholder='Select your business timezone'>
                            {moment.tz.names().map((timezone, index) => (
                                <Select.Option key={index} value={timezone}>{timezone}</Select.Option> 
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={props.colSize}>
                    <Form.Item label='Address' name='address' rules={[{...requiredNoMessage}]}>
                        <Input placeholder='Enter your business address (e.g. Sofia, Bulgaria, Petar Uvaliev 1)' />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
});

export default GeneralTab;