import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Card, Col, Row, Skeleton, Space, Typography } from 'antd';
import { SettingOutlined, GlobalOutlined } from '@ant-design/icons';
import useUserBusinesses from './hooks/useUserBusinesses';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import CreateBusiness from './BusinessProfile/CreateBusiness';

const BusinessProfiles = () => {
    const [createBusinessModal, setCreateBusinessModal] = useState();
    const navigate = useNavigate();
    const businesses = useUserBusinesses();
    const isMobile = useCheckMobileScreen();

    const createBusinessBtn = (
        <Button
            className={isMobile ? `account-page-new-record-btn` : ''}
            type='primary'
            onClick={() => setCreateBusinessModal(true)}
        >
            Create new business
        </Button>
    )

    return (
        <React.Fragment>
            <div className='page__header'>
                <Typography.Title level={3} className='page-title'>Business Profiles</Typography.Title>
                {!isMobile && createBusinessBtn}
            </div>
            <Typography.Text type='secondary'>
                Here are the businesses you're part of. You can create or manage your business profiles here.
            </Typography.Text>
            <div className='account-page-content'>
                {isMobile && createBusinessBtn}
                <Skeleton loading={businesses.loading} active>
                    {businesses.data.length ? (
                        <Row gutter={10}>
                            {businesses.data.map(business => (
                                <Col className='account-page-column' span={isMobile ? 24 : 6} key={business.id}>
                                    <Card
                                        actions={[
                                            <Button
                                                icon={<SettingOutlined />}
                                                className='card-action-btn'
                                                type='link'
                                                onClick={() => navigate(`/manage/${business.slug}/calendar`)}
                                            >
                                                CRM Admin
                                            </Button>,
                                            <Button
                                                icon={<GlobalOutlined />}
                                                className='card-action-btn'
                                                type='link'
                                                onClick={() => navigate(`/${business.slug}`)}
                                            >
                                                Booking Page
                                            </Button>
                                        ]}
                                    >
                                        <Space size='middle'>
                                            <Avatar size='large' src={business.logo}>
                                                {business.name.slice(0, 2).toUpperCase()}
                                            </Avatar>
                                            <div>
                                                <Typography className='account-page-card-business-name'>{business.name}</Typography>
                                                <Typography>{business.industry}</Typography>
                                            </div>
                                        </Space>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Typography>
                            No businesses yet. Create one or contact your admin to join.
                        </Typography>
                    )}
                </Skeleton>
            </div>
            {createBusinessModal && (
                <CreateBusiness open={createBusinessModal} closeHandler={() => setCreateBusinessModal()} refetch={businesses.refetch} />
            )}
        </React.Fragment>
    )
}

export default BusinessProfiles;